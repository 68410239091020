import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect, useMemo, useRef} from 'react';
import {View, Text, Platform} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import {useLocationIndex, usePercentageComplete} from 'src/Hooks/gameHooks';

import {useSafeAreaInsets} from 'react-native-safe-area-context';
import DashedLine from 'src/Modules/DashedLine';
import ExpoImage from 'src/Utils/ExpoImage';
import {DistanceAway} from 'src/ScavengerHunt_v2/Game/DistanceAway';
import UserIcon from 'src/HeaderComponent/UserIcon';
import {TouchableOpacity} from 'react-native-gesture-handler';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {ordinalSuffixOf} from 'src/Utils/helpers';

import Icon from 'react-native-vector-icons/FontAwesome';
import ClassicHuntHeaderTimer from 'src/HeaderComponent/ClassicHuntHeaderTimer';

const GameHeader: React.FC = () => {
  const {width} = useWindowDimensionsSafe();

  const timerLimitMinutes = useTypedSelector(
    (state) => state.game_v2?.timerLimitMinutes,
  );

  const groupPhoto = useTypedSelector((state) => state.group?.info?.groupPhoto);
  const score = useTypedSelector((state) => state.group?.info?.score);
  const ordinal_place = useTypedSelector((state) => {
    // use regular rank
    // from cloud function updateScore and lr_app_group_score_log
    if (!state?.event?.eventId) {
      return (
        state.group?.info?.rankAtFinish ||
        state.group?.info?.ordinal_place ||
        200
      );
    } else {
      let groupScores = Object.values(state.event?.groups || {});
      if (!groupScores) {
        return 200;
      }
      groupScores = groupScores.sort((a, b) =>
        (b.score || 0) > (a.score || 0) ? 1 : -1,
      );

      let rank = 200;
      let currentRank = 1;

      groupScores?.map((score) => {
        if (score.groupId === state?.group?.info?.groupId) {
          rank = currentRank;
        }
        currentRank++;
      });

      return rank;
    }

    // is a corporate event use that instead
  });

  const currentScreen = useTypedSelector(
    (state) => state.current_screen?.currentScreen,
  );
  const hidePhotos = useTypedSelector((state) => state.event?.info?.hidePhotos);

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  const huntType = useTypedSelector(
    (state) => state.group?.info?.huntType || '',
  );

  const companyColor = useTypedSelector(
    (state) => state.event?.info?.branding?.eventColor,
  );

  const hideHeaderWhiteBackground = useTypedSelector(
    (state) =>
      state.current_screen?.currentScreen === 'HuntFinished' ||
      ([
        'coworker_feud',
        'escape_room',
        'murder_mystery',
        'virtual_office_games',
      ].includes(state.group?.info?.huntType || '') &&
        [
          'MainGameStack',
          'HuntLoading',
          'LoadingScreen',
          'TeamPhoto',
          'HuntIntro',
          'HuntOutro',
          'HybridCharacterList',
          'NavigationChallenge',
          'LocationComplete',
          'LocationChallenges',
          'ChallengeAttemptAndResultModal',
          'ConfirmModal',
          'HybridCharacterReveal',
        ].includes(state.current_screen?.currentScreen)),
  );

  const percentageComplete = usePercentageComplete();

  const locationCount = useTypedSelector(
    (state) => state?.game_v2?.locationList?.length || 4,
  );

  const insets = useSafeAreaInsets();

  console.log({percentageComplete});

  const activeScreenRef = useRef<string>(currentScreen);

  const gameHeaderMessageOverride = useTypedSelector(
    (state) => state?.game_v2?.gameHeaderMessageOverride,
  );

  const huntFinished = useTypedSelector(
    (state) => state?.group?.info?.huntFinished,
  );

  useEffect(() => {
    if (
      [
        'ConfirmModal',
        'ChallengeAttemptAndResultModal',
        'ShareModal',
      ]?.includes(currentScreen) ||
      currentScreen?.includes('Modal')
    ) {
      activeScreenRef.current = currentScreen;
    }
  }, [currentScreen]);

  let message: string | React.JSX.Element | null = null;
  if (gameHeaderMessageOverride) {
    message = gameHeaderMessageOverride;
  } else if (activeScreenRef.current == 'TeamPhoto') {
    message = 'Take A Team Photo';
  } else if (
    activeScreenRef.current == 'LocationChallenges' ||
    activeScreenRef.current == 'ChallengeAttemptAndResultModal'
  ) {
    message = isHybridHunt
      ? CurrentSceneText(locationCount, huntType)
      : 'Complete Challenges At Location';
  } else if (activeScreenRef.current == 'LocationComplete') {
    message = isHybridHunt
      ? CurrentSceneText(locationCount, huntType)
      : 'You Finished A Location';
  } else if (activeScreenRef.current == 'HuntIntro') {
    message = isHybridHunt
      ? CurrentSceneText(locationCount, huntType)
      : 'Begin Your Hunt Below';
  } else if (activeScreenRef.current == 'ScavengerHuntGameLobby') {
    message = 'Add Players or Start Your Hunt';
  } else if (activeScreenRef.current == 'HybridCharacterList') {
    message = 'Our Cast of Characters';
  } else if (activeScreenRef.current == 'HybridCharacterReveal') {
    message = 'Your Character Sheet';
  } else if (activeScreenRef.current == 'EventLeaderboard') {
    message = 'Leaderboard';
  } else if (activeScreenRef.current == 'NavigationChallenge') {
    message = isHybridHunt ? CurrentSceneText(locationCount, huntType) : '';
  }

  return useMemo(() => {
    console.log('GameHeader Rerender');
    return (
      <View
        style={{
          backgroundColor: 'transparent',
          marginBottom: !!hideHeaderWhiteBackground ? -30 : -1,
        }}>
        <View
          style={{
            width,
            backgroundColor: !!companyColor
              ? companyColor
              : !!isHybridHunt
              ? constants?.color.orange
              : constants?.color.blue,
            borderBottomRightRadius: 30,
            borderBottomLeftRadius: 30,
            marginBottom: !!hideHeaderWhiteBackground ? -30 : 0,
          }}>
          <View
            style={{
              // backgroundColor: constants?.color.orange,
              width,
              overflow: 'hidden',
              borderBottomRightRadius: 30,
              borderBottomLeftRadius: 30,
              position: 'relative',
            }}>
            <ExpoImage
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: -20,
              }}
              resizeMode={'stretch'}
              source={require('src/Images/GameHeaderEffect.png')}
            />
            <View
              style={{
                marginTop: insets.top + (Platform.OS == 'web' ? 30 : 0),
                paddingLeft: 30,
                paddingRight: 30,
              }}>
              <View
                style={{
                  position: 'relative',
                  height: 50,
                  flexDirection: 'row',

                  justifyContent: 'space-between',
                }}>
                <View
                  style={{
                    position: 'relative',
                    height: 50,
                    flexDirection: 'row',
                  }}>
                  <View style={{width: 50, marginLeft: -10}}>
                    <UserIcon
                      hideBadges
                      containerStyle={{
                        marginTop: -10,

                        width: 50,
                      }}
                      userPhoto={!!groupPhoto ? {uri: groupPhoto} : undefined}
                    />
                  </View>
                  <View>
                    <Text
                      style={{
                        color: 'white',
                        fontFamily: 'JakartaBold',
                        fontSize: 14,
                      }}>
                      {ordinal_place > 100 || !ordinal_place
                        ? 'Rank: Top 50%'
                        : `${ordinalSuffixOf(ordinal_place)} Place`}
                    </Text>
                    <Text
                      style={{
                        color: 'white',
                        fontFamily: 'Jakarta',
                        fontSize: 14,
                      }}>
                      {Math.round(score || 0)?.toLocaleString?.() || 0} pts
                    </Text>
                  </View>
                </View>
                <View
                  style={{
                    position: 'relative',
                    height: 50,
                    flexDirection: 'row',
                    // backgroundColor: 'red',
                    justifyContent: 'space-between',
                  }}>
                  <TouchableOpacity
                    onPress={() => {
                      navigationRef.navigate(
                        'LandingPageMain',
                        null,
                        'ScavengerHunt_v2/List/GameHeader.tsx',
                      );
                    }}
                    style={{padding: 8, paddingHorizontal: 8}}>
                    <ExpoImage
                      style={{width: 22, height: 22}}
                      resizeMode={'contain'}
                      source={require('src/Images/HomeIcon.png')}
                    />
                  </TouchableOpacity>

                  {!hidePhotos && (
                    <TouchableOpacity
                      onPress={() => {
                        navigationRef.navigate(
                          'HuntPhotos',
                          null,
                          'ScavengerHunt_v2/List/GameHeader.tsx',
                        );
                      }}
                      style={{padding: 8, paddingHorizontal: 8}}>
                      <ExpoImage
                        style={{width: 22, height: 22}}
                        resizeMode={'contain'}
                        source={require('src/Images/Icons/PhotosIcon.png')}
                      />
                    </TouchableOpacity>
                  )}
                  <TouchableOpacity
                    testID="Dashboard"
                    onPress={() => {
                      navigationRef.navigate(
                        'PlayerDashboard_v2',
                        null,
                        'ScavengerHunt_v2/List/GameHeader.tsx',
                      );
                    }}
                    style={{padding: 8}}>
                    <Icon
                      style={{width: 22, height: 22}}
                      name={'gear'}
                      size={24}
                      color={'white'}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      navigationRef.navigate(
                        'ReportModal',
                        null,
                        'ScavengerHunt_v2/List/GameHeader.tsx',
                      );
                    }}
                    style={{
                      padding: 8,
                      paddingHorizontal: 8,
                      paddingRight: 0,
                      marginRight: -10,
                    }}>
                    <ExpoImage
                      style={{width: 22, height: 22}}
                      resizeMode={'contain'}
                      source={require('src/Images/FlagIcon.png')}
                    />
                  </TouchableOpacity>
                </View>
              </View>
              {!isHybridHunt ? (
                <View
                  style={{
                    position: 'relative',
                    height: 22,
                    flexDirection: 'row',
                    marginTop: 2,
                  }}>
                  <DashedLine
                    dashColor={'white'}
                    style={{}}
                    widthOveride={
                      ((width -
                        78 -
                        (timerLimitMinutes && !huntFinished ? 70 : 0)) *
                        percentageComplete) /
                      100
                    }
                  />
                  <ExpoImage
                    style={{
                      width: 14,
                      height: 22,
                      top: -10,
                      marginLeft: 2,
                      marginRight: 2,
                    }}
                    resizeMode={'stretch'}
                    source={require('src/Images/Walkingman.png')}
                  />
                  <DashedLine
                    dashColor={'#ffffff88'}
                    style={{}}
                    widthOveride={
                      ((width -
                        78 -
                        (timerLimitMinutes && !huntFinished ? 70 : 0)) *
                        (100 - percentageComplete)) /
                      100
                    }
                  />
                  {!!timerLimitMinutes && !isHybridHunt && (
                    <ClassicHuntHeaderTimer
                      style={{
                        right: -20,
                        height: 30,
                        top: -15,
                        width: 50,
                        borderBottomRightRadius: 10,
                      }}
                      handlePress={() => {
                        console.log('timer pressed');
                      }}
                    />
                  )}
                </View>
              ) : (
                <HybridHuntProgressBar />
              )}
            </View>
          </View>
          {!!message && !isHybridHunt && (
            <View
              style={{
                height: 30,
                paddingLeft: 30,
                paddingRight: 30,
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}>
              <Text
                style={{
                  color: 'white',
                  fontFamily: 'JakartaBold',
                  fontSize: 14,
                }}>
                {message}
              </Text>
            </View>
          )}
          {activeScreenRef.current == 'NavigationChallenge' &&
            !isHybridHunt && (
              <View
                style={{
                  height: 30,
                  paddingLeft: 30,
                  paddingRight: 30,
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexDirection: 'row',
                }}>
                <Text
                  style={{
                    color: 'white',
                    fontFamily: 'JakartaBold',
                    fontSize: 14,
                  }}>
                  Go to stop <CurrentIndexText /> of {locationCount}
                </Text>
                <View>
                  <DistanceAway textOnly={true} />
                </View>
              </View>
            )}
        </View>
        {!!message && !!isHybridHunt && (
          <View
            style={{
              height: 60,
              backgroundColor: constants?.color?.blue,
              flexDirection: 'row',
              borderBottomLeftRadius: 30,
              borderBottomRightRadius: 30,
              zIndex: -10,
              justifyContent: 'center',
              gap: 20,
              alignItems: 'flex-end',
            }}>
            <Text
              style={{
                color: 'white',
                textAlign: 'center',
                marginBottom: 5,
                fontWeight: '600',
                fontSize: 14,
              }}>
              {message}
            </Text>
            {/* <Timer
              style={{
                height: 30,
                width: 50,
              }}
              handlePress={() => {
                console.log('timer pressed');
              }}
            /> */}
          </View>
        )}
      </View>
    );
  }, [
    groupPhoto,
    locationCount,
    timerLimitMinutes,
    percentageComplete,
    ordinal_place,
    score,
    message,
  ]);
};

const CurrentIndexText = () => {
  const locationIndex = useLocationIndex((state) => state?.locationIndex) || 0;
  return <Text>{locationIndex + 1}</Text>;
};

const CurrentSceneText = (locationCount: number, huntType: string) => (
  <Text>
    Scene <CurrentIndexText />
    {!['coworker_feud', 'virtual_office_games']?.includes(huntType) && (
      <Text>
        <Text style={{color: constants?.color?.gray1_80}}> of</Text>{' '}
        {locationCount}
      </Text>
    )}
  </Text>
);

const HybridHuntProgressBar = () => {
  const locationCount = useTypedSelector(
    (state) => state?.game_v2?.locationList?.length || 0,
  );

  const huntType = useTypedSelector(
    (state) => state.group?.info?.huntType || '',
  );

  const {width} = useWindowDimensionsSafe();

  const locationIndex = useLocationIndex((state) => state?.locationIndex) || 0;

  const currentScreen = useTypedSelector(
    (state) => state.current_screen?.currentScreen,
  );

  const activeIndex =
    currentScreen === 'HybridCharacterList'
      ? 0
      : currentScreen === 'HybridCharacterReveal'
      ? 1
      : currentScreen === 'HuntOutro' ||
        currentScreen === 'HuntReviewScreen' ||
        currentScreen === 'HuntSocialBonuses' ||
        currentScreen === 'HuntFinished'
      ? -1
      : currentScreen === 'HuntIntro' || currentScreen === 'TeamPhoto'
      ? huntType === 'murder_mystery'
        ? 2
        : 0
      : huntType === 'murder_mystery'
      ? locationIndex + 2
      : locationIndex;

  // List of steps on the header
  const scenesCount =
    huntType === 'murder_mystery' ? locationCount + 2 : locationCount;

  return ['coworker_feud', 'virtual_office_games']?.includes(huntType) ? (
    <></>
  ) : (
    <View
      style={{
        position: 'relative',
        height: 22,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10,
      }}>
      {[...Array(scenesCount)].map((_, ix, arr) => (
        <View
          key={'progress-part' + ix}
          style={{flexDirection: 'row', alignItems: 'center'}}>
          {activeIndex === ix ? (
            <View
              style={{
                width: 20,
                height: 20,
                borderRadius: 100,
                backgroundColor: constants?.color?.gray1_50,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <View
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 100,
                  backgroundColor: constants?.color?.white,
                }}
              />
            </View>
          ) : (
            <View
              style={{
                width: 10,
                height: 10,
                borderRadius: 100,
                backgroundColor: constants?.color?.gray1_50,
              }}
            />
          )}
          {ix < arr.length - 1 && (
            <DashedLine
              dashColor={'white'}
              style={{}}
              widthOveride={width * 0.65 * (1 / (arr.length - 1))}
            />
          )}
        </View>
      ))}
    </View>
  );
};

export default GameHeader;
