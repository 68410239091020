import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect} from 'react';
import {View, Vibration, ViewStyle} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
///////////////////////////
// Components
import {ContinueButton, BodyText} from 'src/Modules/CommonView';

///////////////////////////
// Utils
import {markSelectionChallengeComplete} from 'src/Utils/huntFunctions_v2';

import {useChallenge} from 'src/Hooks/gameHooks';
import {Slider} from 'react-native-awesome-slider';
import {useSharedValue} from 'react-native-reanimated';

function random(seed = 0) {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

interface NumberGuesserProps {
  onComplete?: () => void;
  singleCompletion?: boolean;
  containerStyle?: ViewStyle;
}

interface SubmissionParams {
  challengeId: string;
  option: number;
  correct: boolean;
  singleCompletion?: boolean;
  overridePointsEarned?: number;
}

const NumberGuesser: React.FC<NumberGuesserProps> = ({
  onComplete,
  singleCompletion,
  containerStyle = {},
}) => {
  const userId = useTypedSelector((state) => state.user?.info?.userId);

  const challenge = useChallenge();
  const correctAnswer = challenge?.correctAnswer || 0;

  const minGuess = Math.max(
    Math.round(Number(correctAnswer) / 10) * 10 - 20,
    0,
  );
  const maxGuess =
    Math.round(Number(correctAnswer) / 10) * 10 +
    20 +
    Math.round(random(Number(correctAnswer) * 123442) / 3) * 10;

  const progress = useSharedValue(Math.floor((maxGuess + minGuess) / 2));
  const min = useSharedValue(minGuess);
  const max = useSharedValue(maxGuess);

  const roundedGuess = Math.round(progress.value);

  useEffect(() => {
    progress.value = Math.floor((maxGuess + minGuess) / 2);
  }, [correctAnswer, maxGuess, minGuess]);

  useEffect(() => {
    min.value = minGuess;
    max.value = maxGuess;
  }, [minGuess, maxGuess]);

  const handleSubmit = () => {
    const correctAnswer = challenge?.correctAnswer || 0;
    const challengeId = challenge?.challengeId || '';

    const submissionParams: SubmissionParams = {
      challengeId,
      option: roundedGuess,
      correct: roundedGuess === Number(correctAnswer),
      singleCompletion,
    };

    // if wrong, 0 points
    if (roundedGuess !== Number(correctAnswer)) {
      submissionParams.overridePointsEarned = 0;
    }

    markSelectionChallengeComplete(submissionParams);

    if (roundedGuess !== Number(correctAnswer)) {
      Vibration.vibrate(400);
    }

    onComplete && onComplete();
  };

  const completions = challenge?.completions || {};

  const userHasCompleted = userId && completions[userId] ? true : false;

  const {width} = useWindowDimensionsSafe();

  return (
    <View
      style={{
        justifyContent: 'center',
        ...containerStyle,
      }}>
      {!userHasCompleted && (
        <View style={{alignItems: 'center'}}>
          <BodyText
            textStyle={{
              textAlign: 'center',
              fontSize: 28,
              lineHeight: 28,
            }}
            largeText
            text={String(roundedGuess)}
          />
          <Slider
            style={{width: width - 20}}
            progress={progress}
            minimumValue={min}
            maximumValue={max}
            onValueChange={(value) => {
              console.log('Current value:', value);
            }}
            disableTapEvent={false} // Enables tap to seek
            step={1} // Adjusts the slider in steps of 1
            snapToStep={true}
          />
          <View>
            <ContinueButton
              text={'Submit'}
              containerStyle={{width: 220, marginVertical: 5, marginTop: 10}}
              background={constants?.color?.orange}
              handlePress={handleSubmit}
            />
          </View>
        </View>
      )}
      {!!userHasCompleted && !!userId && (
        <View style={{marginTop: 20}}>
          {!!roundedGuess && (
            <ContinueButton
              handlePress={() => {
                console.log('already done');
              }}
              text={completions[userId].option || 'Finished'}
              containerStyle={{flex: 1, marginVertical: 5}}
              background={constants?.color?.orange}
            />
          )}
        </View>
      )}
    </View>
  );
};

export default NumberGuesser;
