import constants from 'src/constants';
import React, {useState} from 'react';
import {
  Platform,
  ScrollView,
  TouchableOpacity,
  View,
  Image,
  Text,
} from 'react-native';
import {ContinueButton} from 'src/Modules/CommonView';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {
  BottomTabButton,
  BottomTab,
} from 'src/Modules/TabViews/FooterComponents';
import isIphoneX from 'src/Utils/IphoneXHelper';
const isX = isIphoneX();
import {sendAppFAQFeedback} from 'src/Utils/apiCalls';
///////////// components
import {BodyText} from 'src/Modules/CommonView';
import Icon from 'react-native-vector-icons/FontAwesome';
import SearchBar from 'src/Modules/CommonView/SearchBar';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {SafeAreaView} from 'react-native-safe-area-context';
import getAppType from 'src/Utils/getAppType';

let Questions: {
  title: string;
  questions: {
    title: string;
    content: string;
    // from sql
    id?: string | null;
  }[];
}[] = [
  {
    title: 'Registration',
    questions: [
      {
        title: `How do I get started?`,
        content: `Start by buying tickets from our store!  You’ll need 1 ticket to create a group, and 1 additional ticket for each player. Once you have your tickets, select the city you want to explore. (You’ve got a world of options!) Once you select which hunt you want, tap on ‘pre-register’.  You’ll then have 7 days to go on your hunt. Then pick your theme. Is this a birthday party? A date night? Do you just want an adventure? Take your pick! Then you’ll be given the opportunity to add players to your hunt. Tap the ‘Add Player’ button. Players can join from their own device by scanning a QR Code, or you can add them to your phone by typing in their name. Once your team is ready to go, tap "Start Hunt."`,
      },
      {
        title: `How do I add players?`,
        content: `Once you have created a group, you can add players. Tap the ‘Add Player’ button. Players can join from their own device by scanning a QR Code, or you can add them to your phone by typing in their name. Once your team is ready to go, tap "Start Hunt." Players can also join during the hunt, through the dashboard tab.`,
      },
      {
        title: `How do I join an existing hunt?`,
        content: `Once you pre-register for a hunt you'll be taken to the hunt lobby where you can add teammates and friends to your hunt. Select the "add player" button and use the QR code or the 5 digit invite code to allow friends to join in. You can add players beforehand, or during the hunt by selecting the "dashboard". \nCustom Events (ones created through a company and an account manager) have a custom join code, like "AwesomeCompanyInc2019". Enter this code by selecting "join" in the bottom right hand corner of the screen.  
        `,
      },
      {
        title: `What are characters?`,
        content: `Characters are a fun way to personalize the hunt for each player! The standard five are Photographer, Brainiac, Explorer, Sightseer, and Youngster. The player you choose will determine which type of personal challenges you receive throughout the hunt, so choose wisely. (The Brainiac gets trivia challenges, the Photographer takes pictures, etc.) If you’re on themed hunt, you will see unique characters related to the theme.`,
      },
      {
        title: `What are themes?`,
        content: `Theme upgrades are a great way to customize an adventure to suit a special occasion! Plan your next date night, bachelor, bachelorette, or birthday party with us. We also have seasonal themes available at select times during the year. Themes unlock specific characters that change the types of challenges you'll receive on the hunt. Got something to celebrate? Choose a fun theme! `,
      },
      {
        title: `What are Fox Coins?`,
        content: `Fox Coins are the official currency of Let's Roam! Purchase Fox Coins in the app that you can redeem for scavenger hunt tickets. Much like official government currencies, Fox Coins never expire.`,
      },
      {
        title: `Does everyone need a ticket?`,
        content: `If they want to participate, yes! Each person with a ticket will have the opportunity to answer location-based questions, and complete photo and video challenges. They will also receive personal challenges based on their character. We recommend purchasing for everyone on your team, including children over 6, so each player can have access to the challenges on their own device. If a player does not have a phone you can add up to 10 players on one device and our app will let you know when to pass the device to a different player when it's their turn for a personal challenge!`,
      },
      {
        title: `Are hunts kid friendly?`,
        content: `Yes! Each activity is different, but standard Let’s Roam scavenger hunts are great for families. Within the hunt there is a "Youngster" character designed specifically for kiddos to have a great time. If a child does not have his or her own device, they can share a device with an adult. When it is their turn you can pass the phone to them, so they can do their personal challenges. We do not recommend ghost hunts for young children, however; and bar hunts are for those 21 and older.`,
      },
      {
        title: `Can I bring my dog?`,
        content: `Sure! You will never be asked to go inside non-dog friendly buildings, so dogs can join you on the hunt too.`,
      },
      {
        title: `Are hunts wheelchair accessible?`,
        content: `All of our hunts are outdoor walking tours between 1.5 and 2.5 miles long, if you can travel through a city, you can do a hunt! `,
      },
    ],
  },
  {
    title: 'Hunt',
    questions: [
      {
        title: `What's a scavenger hunt?`,
        content: `A Let’s Roam scavenger hunt is a series of challenges that bring you from spot to spot in a city. Using the GPS of your phone we will guide you to each new landmark, you'll earn bonus points by finding it faster. At each stop you will have a few location-based challenges such as trivia questions or photo challenges related to that specific landmark. You will also receive both individual and team bonus challenges randomly throughout the hunt.`,
      },
      {
        title: `Can I add players during the hunt?`,
        content: `Yes, once the hunt has started you can tap the "Dashboard" button at the bottom of your screen. You'll then see the "Add Players" button. Share either your 5 digit code or the QR code. You can add up to 10 people per device, so bring the whole family!`,
      },
      {
        title: `How do I pause my hunt? `,
        content: `During your hunt tap on the "Dashboard" button on the bottom of your screen. If one player taps "Pause Hunt" it will pause the hunt for everyone on your team, leaving you time to stop for a snack, cool beverage, or to explore a sight more!`,
      },
      {
        title: `Where do I find my player challenges?`,
        content: `During your hunt, at the bottom of the screen is a trophy icon that says "Challenges". When you have a new player challenge you’ll see a small red notification icon here. These are personal challenges just for you, based on the role you selected. These challenges are throughout your hunt.`,
      },
      {
        title: `What are bonus challenges?`,
        content: `Also under the "challenges" tab you'll find bonus challenges. These are challenges for the whole team to work on that are not specifically tied to a location on the hunt. Work together to complete all the bonus challenges and stack up points for your team.`,
      },
      {
        title: `How are points calculated?`,
        content: `Points are calculated based on accuracy, completion, and speed. Here's a breakdown of the scoring: 33% navigation challenges, 33% location based challenges (multiple choice, true/false, etc.), 33% player challenges. Completion and accuracy are most important, it’s not a race!`,
      },
      {
        title: `What if we get lost?`,
        content: `We’ll help you along the way! You can unlock directions during you hunt to get to the next stop. If you don’t know an answer we’ll help you out too.`,
      },
      {
        title: `How do I see my hunt photos?`,
        content: `During your hunt, you can see all of your photos by going to the Dashboard tab in the hunt and selecting photos. If you are part of an event, you can see all of your group’s photos by going to the Dashboard tab during the hunt, then tapping on the Event Leaderboard. After the hunt is done, you can see past hunt photos by selecting the backpack in the top right, then selecting past hunts, and tapping on your hunt.`,
      },
      {
        title: `How do I see how my team is ranking?`,
        content: `After your hunt is finished, you will see how you stack up compared to other teams in your city. Make it to the end of the hunt to see your final scores, accolades, and where you rank on your city’s leaderboard! If you are part of a custom event, you can see how you rank up to other teams in the event by going to the Event Dashboard. You can get there by tapping the Dashboard tab during your hunt, then selecting the Event Leaderboard. Keep an eye on your score to achieve scavenger hunt glory!
`,
      },
      {
        title: `What is a Bar Hunt?`,
        content: `A bar hunt with Let's Roam is a bar crawl like no other! You will receive navigation challenges which direct you to four different bars on the hunt. After you check in to each bar, there will be a set of silly photo and video challenges to complete. During the bar hunt, you play against each other for first place and also compete as a team for the highest group score. Once five challenges have been completed as a team, you can choose to move to the next bar whenever you're ready.`,
      },
    ],
  },
  {
    title: 'Badges, Daily Challenges, and Other',
    questions: [
      {
        title: `What are badges?`,
        content: `When you do an action like finishing a hunt or sharing your hunt photos on social media you might get a badge. Badges are a fun way to track your progress in the app and earn extra coins and other perks. View your badges from the top menu.`,
      },
      {
        title: `What are daily challenges?`,
        content: `Every day we provide 3 free photo or video challenges that you can complete for points. When you complete all three you’ll get to spin the prize wheel!        `,
      },
      {
        title: `How do I create a custom event?`,
        content: `It’s easy to send your group on a custom team builder, bachelorette party, birthday party, or other custom event. Just tap the events tab from the app home screen or call us online at LetsRoam.com. `,
      },
    ],
  },
];

if (getAppType() == 'aa') {
  Questions = [
    {
      title: 'About Adventure Assistant',
      questions: [
        {
          title: `What is Your Personal Travel Assistant?`,
          content: `Start by buying tickets from our store!  You’ll need 1 ticket to create a group, and 1 additional ticket for each player. Once you have your tickets, select the city you want to explore. (You’ve got a world of options!) Once you select which hunt you want, tap on ‘pre-register’.  You’ll then have 7 days to go on your hunt. Then pick your theme. Is this a birthday party? A date night? Do you just want an adventure? Take your pick! Then you’ll be given the opportunity to add players to your hunt. Tap the ‘Add Player’ button. Players can join from their own device by scanning a QR Code, or you can add them to your phone by typing in their name. Once your team is ready to go, tap "Start Hunt."`,
        },
        {
          title: `How does Your Personal Travel Assistant work?`,
          content: `Start by buying tickets from our store!  You’ll need 1 ticket to create a group, and 1 additional ticket for each player. Once you have your tickets, select the city you want to explore. (You’ve got a world of options!) Once you select which hunt you want, tap on ‘pre-register’.  You’ll then have 7 days to go on your hunt. Then pick your theme. Is this a birthday party? A date night? Do you just want an adventure? Take your pick! Then you’ll be given the opportunity to add players to your hunt. Tap the ‘Add Player’ button. Players can join from their own device by scanning a QR Code, or you can add them to your phone by typing in their name. Once your team is ready to go, tap "Start Hunt."`,
        },
        {
          title: `What kind of services can I request from my personal travel assistant?`,
          content: `Start by buying tickets from our store!  You’ll need 1 ticket to create a group, and 1 additional ticket for each player. Once you have your tickets, select the city you want to explore. (You’ve got a world of options!) Once you select which hunt you want, tap on ‘pre-register’.  You’ll then have 7 days to go on your hunt. Then pick your theme. Is this a birthday party? A date night? Do you just want an adventure? Take your pick! Then you’ll be given the opportunity to add players to your hunt. Tap the ‘Add Player’ button. Players can join from their own device by scanning a QR Code, or you can add them to your phone by typing in their name. Once your team is ready to go, tap "Start Hunt."`,
        },
        {
          title: `How personalized are the recommendations from the travel assistant?`,
          content: `Start by buying tickets from our store!  You’ll need 1 ticket to create a group, and 1 additional ticket for each player. Once you have your tickets, select the city you want to explore. (You’ve got a world of options!) Once you select which hunt you want, tap on ‘pre-register’.  You’ll then have 7 days to go on your hunt. Then pick your theme. Is this a birthday party? A date night? Do you just want an adventure? Take your pick! Then you’ll be given the opportunity to add players to your hunt. Tap the ‘Add Player’ button. Players can join from their own device by scanning a QR Code, or you can add them to your phone by typing in their name. Once your team is ready to go, tap "Start Hunt."`,
        },
        {
          title: `Is there a limit to the number of requests I can make?`,
          content: `Start by buying tickets from our store!  You’ll need 1 ticket to create a group, and 1 additional ticket for each player. Once you have your tickets, select the city you want to explore. (You’ve got a world of options!) Once you select which hunt you want, tap on ‘pre-register’.  You’ll then have 7 days to go on your hunt. Then pick your theme. Is this a birthday party? A date night? Do you just want an adventure? Take your pick! Then you’ll be given the opportunity to add players to your hunt. Tap the ‘Add Player’ button. Players can join from their own device by scanning a QR Code, or you can add them to your phone by typing in their name. Once your team is ready to go, tap "Start Hunt."`,
        },
        {
          title: `How do you ensure the quality and reliability of your recommendations?`,
          content: `Start by buying tickets from our store!  You’ll need 1 ticket to create a group, and 1 additional ticket for each player. Once you have your tickets, select the city you want to explore. (You’ve got a world of options!) Once you select which hunt you want, tap on ‘pre-register’.  You’ll then have 7 days to go on your hunt. Then pick your theme. Is this a birthday party? A date night? Do you just want an adventure? Take your pick! Then you’ll be given the opportunity to add players to your hunt. Tap the ‘Add Player’ button. Players can join from their own device by scanning a QR Code, or you can add them to your phone by typing in their name. Once your team is ready to go, tap "Start Hunt."`,
        },
        {
          title: `How do active requests work?`,
          content: `Start by buying tickets from our store!  You’ll need 1 ticket to create a group, and 1 additional ticket for each player. Once you have your tickets, select the city you want to explore. (You’ve got a world of options!) Once you select which hunt you want, tap on ‘pre-register’.  You’ll then have 7 days to go on your hunt. Then pick your theme. Is this a birthday party? A date night? Do you just want an adventure? Take your pick! Then you’ll be given the opportunity to add players to your hunt. Tap the ‘Add Player’ button. Players can join from their own device by scanning a QR Code, or you can add them to your phone by typing in their name. Once your team is ready to go, tap "Start Hunt."`,
        },
      ],
    },
  ];
}

const HelpModal: React.FC = () => {
  const [searchText, setSearchText] = useState<string>('');
  const {width, height} = useWindowDimensionsSafe();
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: constants?.color?.white,
        position: 'relative',
      }}>
      <ScrollView
        style={{
          flex: 1,
          minHeight: height,
        }}>
        <View style={{flex: 1, alignItems: 'center', paddingBottom: 200}}>
          <Image
            style={{width: 95, height: 83}}
            source={require('src/Images/FAQ_Hero.png')}
          />
          <SearchBar
            containerStyle={{
              width: width - 20,
              // height: 50,
              backgroundColor: 'transparent',
              borderWidth: 0,
              borderBottomColor: 'transparent',
              borderTopColor: 'transparent',
              marginBottom: 10,
            }}
            onChangeText={(text) => setSearchText(text)}
            value={searchText}
            onFocus={() => {
              // setActiveSearch(true);
            }}
            onBlur={() => {
              //!searchText && setActiveSearch(false);
            }}
            onClear={() => {
              setSearchText('');
            }}
            inputStyle={{
              height: 40,
              backgroundColor: constants?.color?.white,
              borderRadius: 10,
              marginLeft: 10,
              color: constants?.color?.gray3,
              marginRight: 5,
              paddingLeft: 30,
              paddingBottom: Platform.OS == 'android' ? 11 : 0,
            }}
            placeholder="Search Frequently Asked Questions"
          />
          <View>
            {!!Questions &&
              Object.values(Questions)?.map?.((questionSection, index) => {
                // console.log('questionSection', {questionSection});
                const title = questionSection?.title || '';
                const questions = questionSection?.questions || [];

                return (
                  <View
                    key={`${title}_q${index}`}
                    style={{width: width - 40, marginBottom: 20}}>
                    <FAQItem id={`${title}_q${index}`} title={title} header />
                    {!!questions &&
                      questions
                        .filter((question) => {
                          if (!searchText) {
                            return true;
                          }
                          return (
                            question?.title
                              ?.toLowerCase?.()
                              ?.includes?.(searchText?.toLowerCase?.()) ||
                            question?.content
                              ?.toLowerCase?.()
                              ?.includes?.(searchText?.toLowerCase?.())
                          );
                        })
                        ?.map?.((question, idx) => {
                          // console.log('faqQuestion', {question});
                          return (
                            <FAQItem
                              key={`_q${index}-${idx}`}
                              id={question?.id || `_q${index}-${idx}`}
                              title={question?.title}
                              answer={question?.content}
                            />
                          );
                        })}
                  </View>
                );
              })}
          </View>
        </View>
      </ScrollView>
      <BottomTab
        height={isX ? 75 : 65}
        tabStyle={{
          alignItems: 'flex-start',
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}>
        <BottomTabButton
          text={'Go Back'}
          iconName={'arrow-left-circle'}
          handlePress={() => navigationRef?.goBack('LandingPage/FAQ.tsx')}
          buttonStyle={{marginTop: 5}}
        />
        <BottomTabButton
          text={'Contact Support'}
          iconName={'phone'}
          handlePress={() =>
            navigationRef.navigate('SupportModal', {}, 'LandingPage/FAQ.tsx')
          }
          buttonStyle={{marginTop: 5}}
        />
      </BottomTab>
    </SafeAreaView>
  );
};

type FAQItemProps = {
  title: string;
  id: string;
  header?: boolean;
} & ({header: true; answer?: never} | {header?: false; answer: string});
const FAQItem = ({title, answer, header = false, id}: FAQItemProps) => {
  const [focused, setFocused] = useState<boolean>(false);
  const [feedbackLeft, setFeedbackLeft] = useState<boolean>(false);
  const [hideFeedbackLeft, setHideFeedbackLeft] = useState<boolean>(false);
  const handlePress = () => {
    sendAppFAQFeedback('add', id);
    setFocused(!focused);
  };
  const handleFeedbackPress = (liked: boolean) => {
    sendAppFAQFeedback(liked ? 'yes' : 'no', id);
    setFeedbackLeft(true);
    setTimeout(() => {
      setHideFeedbackLeft(true);
    }, 1500);
  };
  const {width} = useWindowDimensionsSafe();
  return (
    <TouchableOpacity
      onPress={handlePress}
      disabled={!!header}
      style={{
        width: width - 40,
        borderBottomColor: constants?.color?.gray1_50,
        borderBottomWidth: 2,
        paddingBottom: 1,
      }}>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: focused ? 1 : 10,
          paddingTop: 7,
        }}>
        <BodyText
          text={title}
          textStyle={{
            color: header ? constants?.color?.orange : constants?.color?.gray3,
            marginTop: header ? 5 : 10,
            fontWeight: header ? '700' : '300',
            fontSize: header ? 18 : 16,
            lineHeight: header ? 22 : 16,
            marginLeft: 5,
          }}
        />
        {!header && (
          <Icon
            name={!!focused ? 'chevron-down' : 'chevron-right'}
            size={12}
            color={constants?.color?.gray2}
            style={{marginTop: 10, marginRight: 5}}
          />
        )}
      </View>
      {!!focused && (
        <BodyText
          text={answer}
          textStyle={{
            color: header ? constants?.color?.orange : constants?.color?.gray3,
            fontWeight: '300',
            fontSize: 16,
            lineHeight: 21,
            paddingBottom: 20,
          }}
        />
      )}
      {!!focused && !feedbackLeft && (
        <View>
          <View
            style={{
              flexDirection: 'row',
              height: 50,
              marginHorizontal: 10,
              marginTop: 10,
              marginBottom: 0,
            }}>
            <ContinueButton
              text={'This Is Useful'}
              containerStyle={{
                backgroundColor: constants?.color?.orange,
                height: 40,
              }}
              handlePress={() => handleFeedbackPress(true)}
            />
          </View>
          <View
            style={{
              flexDirection: 'row',
              height: 50,
              marginTop: 5,
              marginHorizontal: 10,
              marginBottom: 10,
            }}>
            <ContinueButton
              text={'This Is Not Useful'}
              containerStyle={{
                backgroundColor: constants?.color?.blue,
                height: 40,
              }}
              handlePress={() => handleFeedbackPress(false)}
            />
          </View>
        </View>
      )}
      {!!focused && !!feedbackLeft && !hideFeedbackLeft && (
        <Text
          style={{
            fontSize: 20,
            marginTop: 5,
            marginBottom: 10,
            width: '100%',
            textAlign: 'center',
            color: constants?.color?.gray3,
            fontFamily: 'Alternate Gothic No3 D',
          }}>
          Thanks for the feedback!
        </Text>
      )}
    </TouchableOpacity>
  );
};
export default HelpModal;
