import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useEffect, useState} from 'react';
import {
  View,
  Vibration,
  ViewStyle,
  TextInput,
  Text,
  Keyboard,
} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

///////////////////////////
///////////// components
import {ContinueButton, BodyText} from 'src/Modules/CommonView';

///////////////////////////
///////////// utils
import {markSelectionChallengeComplete} from 'src/Utils/huntFunctions_v2';

import {useChallenge} from 'src/Hooks/gameHooks';

import ExpoImage from 'src/Utils/ExpoImage';
import {cloudFlareOptimizeImage, levenshteinDistance} from 'src/Utils/helpers';

function random(seed = 0) {
  const x = Math.sin(seed++) * 10000;
  return x - Math.floor(x);
}

interface TextQuestionProps {
  onComplete?: () => void;
  containerStyle?: ViewStyle;
  prompt?: string;
  showPhoto?: boolean;
  hideHint?: boolean;
  showBlanks?: boolean;
}

function countWordsAndTrim(text = '') {
  // Trim the text
  const trimmedText = text.trim();

  // Count the words in the trimmed text
  // Split by spaces (accounting for multiple spaces) and filter out empty strings
  const wordCount = trimmedText.split(/\s+/).filter(Boolean).length;

  return wordCount;
}

const TextQuestion: React.FC<TextQuestionProps> = ({
  onComplete,
  containerStyle = {},
  prompt = '',
  showPhoto = false,
  hideHint = false,
  showBlanks = false,
}) => {
  const userId = useTypedSelector((state) => state.user?.info?.userId);

  const challenge = useChallenge();
  const correctAnswer =
    challenge?.answers?.find((answer) => answer.correct) || {};

  const [typedText, setTypedText] = useState('');

  const formattedCorrectOption = correctAnswer?.option?.toLowerCase?.() || '';
  const wordCount = countWordsAndTrim(formattedCorrectOption);

  const handleSubmit = () => {
    if (!typedText) return;

    const formattedTypedText = typedText?.toLowerCase?.();
    const formattedCorrectOption = correctAnswer?.option?.toLowerCase?.();

    const challengeId = challenge?.challengeId || '';

    if (correctAnswer?.option && formattedCorrectOption) {
      let distance = levenshteinDistance(
        typedText.trim().toLowerCase(),
        correctAnswer.option.trim().toLowerCase(),
      );

      distance = distance >= 0 ? distance : 10;

      const pressData = {
        challengeId,
        option: formattedTypedText,
        correct: false,
        singleCompletion: true,
        overridePointsEarned: 0,
      };

      if (
        !!(formattedCorrectOption?.length >= 5 && distance <= 2) ||
        !!(
          correctAnswer.option.length < 5 &&
          typedText.trim().toLowerCase() ===
            correctAnswer.option.trim().toLowerCase()
        )
      ) {
        pressData['correct'] = true;
        pressData['overridePointsEarned'] = challenge?.maxPoints || 500;
      }

      markSelectionChallengeComplete(pressData);

      onComplete && onComplete?.();
    }
  };

  const completions = challenge?.completions || {};

  const userHasCompleted = userId && completions[userId] ? true : false;

  const {width} = useWindowDimensionsSafe();

  return !userHasCompleted ? (
    <View>
      {!!challenge?.challengePhoto && showPhoto ? (
        <View
          style={{
            borderBottomWidth: 1,
            borderBottomColor: constants?.color?.white1,
          }}>
          <ExpoImage
            source={{
              uri: cloudFlareOptimizeImage(challenge?.challengePhoto),
            }}
            style={{
              width: width - 20,
              height: 50,
              marginTop: 10,
              marginBottom: 10,
            }}
            resizeMode={'contain'}
          />
        </View>
      ) : (
        showPhoto && <BodyText text={challenge?.question || ''} />
      )}
      <View style={{alignItems: 'center'}}>
        <View
          style={{
            flexDirection: 'column',
            alignItems: 'center',
            marginVertical: 15,
          }}>
          {prompt && (
            <Text style={{fontFamily: constants.font.Jakarta, fontSize: 15}}>
              {prompt}
            </Text>
          )}
          {challenge?.additionalInfo && !hideHint && (
            <Text style={{fontFamily: constants.font.Jakarta, fontSize: 15}}>
              Hint: {challenge.additionalInfo}
            </Text>
          )}
          {showBlanks && correctAnswer?.option && (
            <View
              style={{
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
              }}>
              {[...Array(correctAnswer?.option.trim().length || 0)].map(
                (_v, i) => (
                  <View
                    key={`line-${i}`}
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <BodyText
                      textStyle={{
                        color: constants?.color?.gray3,
                        textAlign: 'center',
                        fontSize: 14,
                        fontWeight: '700',
                        marginTop: 0,
                      }}>
                      ___
                    </BodyText>
                  </View>
                ),
              )}
            </View>
          )}
        </View>
        <TextInput
          testID={'fillInTheBlank'}
          style={{
            width: width - 20,
            height: 40,
            backgroundColor: '#D9D9D6',
            borderRadius: 10,
            textAlign: 'center',
            color: '#505759',
            fontFamily: constants.font.Jakarta,
            fontSize: 15,
            elevation: 10,
            shadowColor: 'rgba(0, 0, 0, 0.8)',
            shadowOffset: {width: 10, height: 14},
            shadowOpacity: 0.25,
            shadowRadius: 20,
          }}
          placeholderTextColor="#505759"
          placeholder="Enter your guess here"
          value={typedText}
          onSubmitEditing={() => {
            handleSubmit();
            Keyboard.dismiss();
          }}
          onChangeText={(text) => setTypedText(text)}
        />
        <View style={{marginTop: 20}}>
          <ContinueButton
            text={'Submit Challenge'}
            containerStyle={{width: width - 20}}
            handlePress={() => {
              handleSubmit();
              Keyboard.dismiss();
            }}
          />
        </View>
        <Text style={{marginTop: 20}}>
          Hint: The answer is {wordCount} word{wordCount > 1 ? 's' : ''}.
        </Text>
      </View>
    </View>
  ) : (
    <View>
      <Text
        style={{
          fontFamily: constants.font.Jakarta,
          fontSize: 25,
          textAlign: 'center',
          marginBottom: 0,
          color: '2px solid rgb(80, 87, 89)',
        }}>
        The correct answer was:{' '}
        <Text style={{fontFamily: constants.font.JakartaBold}}>
          {correctAnswer?.option}
        </Text>
      </Text>
    </View>
  );
};

export default TextQuestion;
