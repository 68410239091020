import * as t from 'io-ts';
import {validateResult} from 'src/types/typesHelpers';

const TBonusScoreDataIO = t.partial({
  accuracy_reason: t.string,
  accuracy_score: t.number,
  fun_of_photo_creativity: t.number,
  fun_of_photo_creativity_reason: t.string,
  number_of_people: t.number,
  photo_brief_description: t.string,
  photo_challenge_goal: t.string,
});

const TChallengeAnswersIO = t.partial({
  key: t.union([t.string, t.null]),
  option: t.union([t.string, t.null]),
  correct: t.union([t.boolean, t.null]),
  selectedAt: t.union([t.string, t.null]),
  answer: t.union([t.string, t.null]),
  points: t.union([t.string, t.null]),
});

export type TChallengeAnswers = t.TypeOf<typeof TChallengeAnswersIO>;

const TCompletionIO = t.partial({
  photoUrl: t.union([t.string, t.null]), // for photo challenges
  option: t.union([t.string, t.null]), // for multiple choice
  answeredCorrectly: t.boolean,
  userId: t.union([t.string, t.null]),
  guesses: t.union([t.array(t.string), t.null]), // for coworker feud
  pointsEarned: t.union([t.string, t.number]), // for coworker feud
});

export type TCompletion = t.TypeOf<typeof TCompletionIO>;

// io-ts type for Challenge
const TChallengeIO = t.intersection([
  t.type({
    challengeId: t.string,
    db_id: t.string,
    task: t.string,
    type: t.string,
  }),
  t.partial({
    challenge_id: t.union([t.string, t.null]),
    scheduled_id: t.string, // Adjust the type if you have a more specific format for this string
    completed: t.boolean,
    userId: t.union([t.string, t.null]), // for player challenges
    completionTime: t.union([t.number, t.null]),
    pointsEarned: t.union([t.number, t.null]),
    coin_value: t.union([t.number, t.null]),
    pointsPossible: t.union([t.number, t.null]),
    hintsDeductions: t.union([t.number, t.null]),
    character: t.union([t.string, t.null]), // for player challenges
    guess: t.union([t.string, t.null]),
    photoUrl: t.union([t.string, t.null]),
    photoURL: t.union([t.string, t.null]),
    photo_url: t.union([t.string, t.null]),
    UUID: t.union([t.string, t.null]),
    shareUUID: t.string, // uuid for sharing completed challenge
    completeTime: t.string, // when challenge completed
    deliveryTime: t.number, // for player challenge when to deliver
    downloadURL: t.union([t.string, t.null]), // photo after upload if photo challenge
    thumbnail: t.union([t.string, t.null]), // thumbnail for video challenge
    iosDeliveryTime: t.string, // used for ios
    completionId: t.string, // this is a key for the challenge
    completions: t.record(t.string, TCompletionIO),
    // Add other properties of the challenge as required here.
    isVideo: t.boolean, // used for video challenges
    description: t.union([t.string, t.null]),
    firstName: t.union([t.string, t.null]), // used for player challenges,
    question: t.union([t.string, t.null]),
    answers: t.array(TChallengeAnswersIO),
    selection_polygon: t.union([t.string, t.null]),
    postContent: t.union([t.string, t.null]),
    playerPointsEarned: t.union([t.number, t.null]),
    groupPointsEarned: t.union([t.number, t.null]),
    challengeMedium: t.union([t.string, t.null]),
    challenge_medium: t.union([t.string, t.null]),
    points: t.union([t.number, t.null]),
    completion: TCompletionIO,
    challengeList: t.array(t.string),
    challengeText: t.union([t.string, t.null]),
    challenge: t.union([t.string, t.null]),
    additional_info: t.union([t.string, t.null]),
    additionalInfo: t.union([t.string, t.null]),
    hintURL: t.union([t.string, t.null]),
    challengePhoto: t.union([t.string, t.null]),
    challenge_photo: t.union([t.string, t.null]),
    maxPoints: t.union([t.number, t.null]),
    correctAnswer: t.union([t.string, t.null]),
    startTime: t.union([t.number, t.null]),
    photoLarge: t.union([t.string, t.null]),
    timerSeconds: t.union([t.number, t.null]),
    isLocal: t.union([t.boolean, t.null]),
    name: t.union([t.string, t.null]),
    lightningTimerStart: t.union([t.number, t.null]),
    lightning_timer_seconds: t.union([t.number, t.null]),
    lightningBonusPointsEarned: t.union([t.number, t.null]),
    lr_app_challenge_completion_id: t.union([t.string, t.null]),
    locationId: t.union([t.string, t.null]), // used for classic challenges
    required_checkout_location: t.union([t.boolean, t.null]), // used for list style hunt final location checkout
    subChallengeList: t.array(t.string), // used for bar hunt sub challenges
    maxCompletionsRemaining: t.union([t.number, t.null]), // used for bar hunt sub challenges
    completionsCount: t.union([t.number, t.null]), // used for bar hunt sub challenges
    pointsRemaining: t.union([t.number, t.null]), // used for bar hunt sub challenges
    isTotalCompleted: t.union([t.boolean, t.null]), // used for bar hunt sub challenges
    challengeHandleDoneOverride: t.any, // used for override of challenge handle done
    scoreReviewTime: t.union([t.number, t.null]), // used for bar hunt sub challenges
    post_task_info: t.union([t.string, t.null]), // text that shows after the challenge is completed
    bonusScoreData: t.union([TBonusScoreDataIO, t.undefined]),
    lat: t.union([t.number, t.null]),
    long: t.union([t.number, t.null]),
    locationSpeedBonus: t.union([t.number, t.null]),
  }),
]);

// Derive the TypeScript type from the io-ts type
export type TChallenge = t.TypeOf<typeof TChallengeIO>;

// Type guard for runtime validation
export const isTChallenge = (
  input: unknown,
  showErrors: boolean = false,
): input is TChallenge => {
  return validateResult(input, TChallengeIO, 'TChallenge', showErrors);
};
