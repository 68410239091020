import constants from 'src/constants';
import React from 'react';
import {TouchableOpacity, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import ExpoImage from 'src/Utils/ExpoImage';
///////////////////////////
///////////// components
import {
  BodyText,
  CloseIconButton,
  ContinueButton,
} from 'src/Modules/CommonView';

import {navigationRef} from 'src/Nav/navigationHelpers';
import {useImageSource, useLocation} from 'src/Hooks/gameHooks';
import useParams from 'src/Hooks/useParams';

const NavigationChallengeScoreModal: React.FC = () => {
  const {width, height} = useWindowDimensionsSafe();

  const {onClose} = useParams<'NavigationChallengeScoreModal'>();
  const location = useLocation();
  const name = location?.name || 'Location';
  const groupPointsEarned = location?.groupPointsEarned || 0;
  const imageSource = useImageSource();

  return (
    <SafeAreaView style={{flex: 1, justifyContent: 'flex-end'}}>
      <TouchableOpacity
        style={{flex: 1}}
        onPress={() => {
          navigationRef?.goBack(
            'ScavengerHunt_v2/Classic/NavigationChallengeScoreModal.tsx',
          );
          onClose && onClose();
        }}
      />
      <View
        style={{
          width,
          backgroundColor: constants?.color?.white,
          borderRadius: 10,
          paddingVertical: 5,
          position: 'relative',
        }}>
        <View style={{alignItems: 'center'}}>
          <ExpoImage
            style={{
              width: width - 20,
              height: height <= 760 ? 210 : 240,
            }}
            source={imageSource}
            resizeMode={'contain'}
          />
        </View>

        <View style={{marginTop: 10, marginBottom: 20}}>
          <BodyText
            text={'🎉 Awesome Job! 🎉'}
            textStyle={{
              textAlign: 'center',
              fontWeight: 'bold',
              fontSize: 18,
              lineHeight: 20,
              fontFamily: constants?.font.circularBold,
            }}
          />
          <BodyText
            text={'You & Your Team Earned'}
            textStyle={{
              textAlign: 'center',
              fontWeight: '700',
            }}
          />

          <BodyText
            text={`${groupPointsEarned || '...'} pts`}
            textStyle={{
              textAlign: 'center',
              fontWeight: '700',
              fontSize: 24,
              lineHeight: 26,
              color: constants?.color?.orange,
            }}
          />
          <BodyText
            text={`for checking in at`}
            textStyle={{
              textAlign: 'center',
            }}
          />
          <BodyText
            text={name}
            textStyle={{
              textAlign: 'center',
              fontWeight: '700',
              fontSize: 18,
              lineHeight: 20,
            }}
          />

          <BodyText
            text={'📈 Point Breakdown'}
            textStyle={{
              textAlign: 'center',
              fontWeight: '700',
              fontSize: 20,
              lineHeight: 24,
              marginTop: 24,
              fontFamily: constants?.font.circularBold,
            }}
          />
          <BodyText
            text={'✅ 500 / 500 for Completion'}
            textStyle={{
              textAlign: 'center',
              fontWeight: '700',
              fontSize: 20,
              lineHeight: 25,
            }}
          />

          {!!location?.distanceAwayBonusPoints && (
            <BodyText
              text={`📍${location?.distanceAwayBonusPoints} / 500 for Distance`}
              textStyle={{
                textAlign: 'center',
                fontWeight: '700',
                fontSize: 20,
                lineHeight: 24,
              }}
            />
          )}
        </View>
        <View style={{height: 55, paddingBottom: 10, paddingHorizontal: 5}}>
          <ContinueButton
            text={'View Challenges'}
            handlePress={() => {
              navigationRef?.goBack(
                'ScavengerHunt_v2/Classic/NavigationChallengeScoreModal.tsx',
              );
              onClose && onClose();
            }}
            testID={'viewLocationChallenges'}
            background={constants?.color?.blue}
          />
        </View>

        <CloseIconButton
          handlePress={() => {
            navigationRef?.goBack(
              'ScavengerHunt_v2/Classic/NavigationChallengeScoreModal.tsx',
            );
            onClose && onClose();
          }}
          side="right"
        />
      </View>
    </SafeAreaView>
  );
};

export default NavigationChallengeScoreModal;
