import {Alert} from 'react-native';

import * as Device from 'expo-device';

import {logForCrashlytics} from 'src/Utils/fireUtils';
import {getReduxState} from 'src/Utils/helpers';
import {mmkvStorage} from 'src/Utils/mmkvStorage';
import {logCustomAnalyticsEvent} from 'src/Utils/fireUtils';
import {THunt, isTHunt} from 'src/types/THunt';

import {onScoreResponse} from 'src/types/TNavigationRouteParams';
import {TNewWanderLocation} from 'src/Redux/reducers/local.reducer';
import getAppType from './getAppType';

const getBaseUrl = () => {
  const useLocalHost = getReduxState((state) => state?.app_info?.useLocalHost);
  const actuallyUseBaseURL = __DEV__ && !!useLocalHost && !Device.isDevice;
  console.log({actuallyUseBaseURL, useLocalHost});
  return actuallyUseBaseURL
    ? `https://letsroam.local.com`
    : `https://api.letsroam.com`;
};

export const fetchWithParams = async <
  TResponse extends TJSONValue = TJSONObject, // default to a JSONObject
  TParams = Record<string, unknown>,
>(
  url: string,
  params?: TParams,
): Promise<TResponse> => {
  const requestId = 'request-' + Math.round(Math.random() * 100000000) + ':';
  logForCrashlytics(requestId + 'fetchWithParams fired in apiCalls');
  try {
    logForCrashlytics(requestId + 'the fetch url was ' + url);
    logForCrashlytics(requestId + 'the params were ' + JSON.stringify(params));
    const response = await fetch(url, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(params),
    });

    if (response.status < 300) {
      return (await response.json?.()) as TResponse;
    } else {
      console.log({url});
      console.error(requestId + 'api fetch call failed', {response});
      throw new Error(requestId + 'api fetch call failed');
    }
  } catch (error: unknown) {
    console.log(requestId + ' failed 2', {error});
    throw error; // You might want to re-throw the error so the caller is aware of the failure.
  }
};

export const api_delete_user_or_group = async (params: {
  id: string;
  event_id?: string | null;
  type: 'user' | 'group';
}): Promise<void> => {
  const url = `${getBaseUrl()}/api/v1/delete_user_or_group`;

  console.log('delete_user_or_group', {url, params});
  try {
    const response = await fetchWithParams(url, params);
    // return Alert.alert('', JSON.stringify(response));
    console.log('delete response', {response});
  } catch (error: unknown) {
    console.error('error deleting group', {error});
  }
};

type NotificationParams = {
  topic: string;
  title: string;
  body: string;
};

export const apiSendEventPushNotification = async (
  params: NotificationParams,
): Promise<void> => {
  const url = `${getBaseUrl()}/api/v1/app_notifications/send_instant_push_notification`;

  console.log('apiSendEventPushNotification', {url, params});
  try {
    const response = await fetchWithParams(url, params);
    // return Alert.alert('', JSON.stringify(response));
    console.log('push notification sent', {response});
  } catch (error: unknown) {
    console.error('push notification send error', {error});
  }
};

export const apiGetAllHunts = async (): Promise<THunt[] | null> => {
  const url = `${getBaseUrl()}/api/v1/hunts/get_all_hunts?password=asf4fvadfv31das&get_audio_tours=1`;
  const token = 'ef4244b7-f677-406b-a603-ce933851a418';

  try {
    const huntResult = (await fetchWithParams(url, {token})) as THunt[];
    if (!huntResult || !huntResult.length) {
      console.error('apiGetAllHunts failed');
      return null;
    }
    const hunts: THunt[] = [];

    let malFormattedHunt: unknown;
    let malFormattedHuntCount: number = 0;

    huntResult.map((hunt: unknown) => {
      try {
        if (typeof hunt == 'object') {
          const formattedHunt = {...hunt};
          if ('lat' in formattedHunt) {
            formattedHunt.lat = Number(formattedHunt.lat);
          }
          if ('long' in formattedHunt) {
            formattedHunt.long = Number(formattedHunt.long);
          }

          if ('is_free' in formattedHunt) {
            formattedHunt.is_free =
              formattedHunt.is_free === '1' || formattedHunt.is_free === 1;
          }

          if ('total_reviews' in formattedHunt) {
            formattedHunt.total_reviews = Number(formattedHunt.total_reviews);
          }

          if ('star_rating' in formattedHunt) {
            formattedHunt.star_rating = Number(formattedHunt.star_rating || 0);
          }

          if (isTHunt(formattedHunt)) {
            hunts.push(formattedHunt as THunt);
          } else {
            throw Error('not correctly formatted');
          }
        }
      } catch (huntResultError) {
        malFormattedHunt = hunt;
        malFormattedHuntCount++;
      }
    });

    if (!!malFormattedHuntCount) {
      console.error(`Warning ${malFormattedHuntCount} mal-formatted hunt`, {
        malFormattedHunt,
      });
    }

    if (!huntResult) {
      console.error('apiGetAllHunts failed bad formatting');
      return null;
    }

    // console.log({huntResult});

    //
    // check here if Hunt[]

    return huntResult;
  } catch (error: unknown) {
    console.error('apiGetAllHunts error');
    return null;
  }
};

export const apiTrackUserLogin = async ({
  user_id,
  latitude,
  longitude,
}: {
  user_id: string;
  latitude: number;
  longitude: number;
}) => {
  const url = `${getBaseUrl()}/api/v1/users/track_user_login`;
  const token = 'ef4244b7-f677-406b-a603-ce933851a418';
  const params = {user_id, token, latitude, longitude};

  try {
    const result = await fetchWithParams(url, params);

    return result;
  } catch (error: unknown) {
    return {error};
  }
};

export const apiFlagPhotos = async ({
  photoId,
  photoUrl,
}: {
  photoId?: string;
  photoUrl?: string;
}) => {
  const url = `${getBaseUrl()}/api/v1/flag_photo`;
  const params = {photoId, photoUrl};

  try {
    const result = await fetchWithParams(url, params);

    return result;
  } catch (error: unknown) {
    return {error};
  }
};

export const apiGetHuntThemes = async () => {
  const url = `${getBaseUrl()}/api/v1/app_themes`;
  const token = '7a21c094-2d88-4434-b2b5-bb8a6f3a71c3';
  const params = {token};

  try {
    const result = await fetchWithParams(url, params);

    return result;
  } catch (error: unknown) {
    return {error};
  }
};

interface apiLRrAppChallengeFeedbackParams {
  lr_app_challenge_id: string;
  lr_app_challenge_completion_id?: string | null;
  liked: boolean;
  user_id: string | null;
  feedback: string | null;
  challenged_by_user: boolean | null;
}

export const apiLRrAppChallengeFeedback = async ({
  lr_app_challenge_id,
  lr_app_challenge_completion_id,
  liked,
  user_id,
  feedback,
  challenged_by_user,
}: apiLRrAppChallengeFeedbackParams) => {
  const url = `${getBaseUrl()}/api/v1/app/community/leave_lr_app_challenge_feedback`;

  console.log('apiLRrAppChallengeFeedback', {
    lr_app_challenge_id,
    lr_app_challenge_completion_id,
    liked,
    user_id,
    feedback,
    challenged_by_user,
  });
  try {
    const response = await fetchWithParams(url, {
      lr_app_challenge_id,
      lr_app_challenge_completion_id,
      liked,
      user_id,
      feedback,
      challenged_by_user,
    });
    // return Alert.alert('', JSON.stringify(response));
    // return response;
    if (response && response.response_title) {
      Alert.alert(
        response.response_title as string,
        response.response_body as string,
      );
    }
  } catch (error: unknown) {
    return {error};
  }
};

interface fetchAddQuestionReviewParams {
  liked: boolean;
  db_id?: string;
  userFeedback: string | null;
  lr_app_challenges_completions_id?: string;
}

export const fetchAddQuestionReview = async ({
  liked,
  db_id,
  userFeedback,
  lr_app_challenges_completions_id = 'none',
}: fetchAddQuestionReviewParams) => {
  logForCrashlytics('fetchAddQuestionReview fired in apiCalls');
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  const huntId = getReduxState((state) => state?.group?.info?.huntId);

  const url =
    `${getBaseUrl()}/api/v1/hunts/add_question_review` +
    `?liked=${liked ? 'yes' : 'no'}` +
    `&auth_code=${groupId}` +
    `&question_id=${db_id}` +
    `&hunt_id=${huntId}` +
    `&r_role=${'none'}` +
    `&lr_app_challenges_completions_id=${lr_app_challenges_completions_id}` +
    `&additional_comments=${userFeedback || 'none'}`;

  try {
    const response = await fetch(url);

    if (response.status < 300) {
      const success = await response.json?.();
      return success;
    }
  } catch (error: unknown) {
    return {error};
  }
};

export const apiLeaveQuestionReview = async ({
  liked = false,
  questionId = '',
  appChallengeId = '',
  groupId = '',
  routeId = '',
  huntId = '',
  screen = '',
  userId = '',
  locationId = '',
  playTestState = 0,
  userFeedback = '',
  app_version = '',
}) => {
  const url =
    `${getBaseUrl()}/api/v1/hunts/add_question_review` +
    `?liked=${!!liked ? 'yes' : 'no'}` +
    `&auth_code=${groupId || ''}` +
    `&question_id=${questionId || ''}` +
    `&app_challenge_id=${appChallengeId || ''}` +
    `&location_id=${locationId || ''}` +
    `&hunt_id=${huntId || ''}` +
    `&route_id=${routeId || ''}` +
    `&user_id=${userId || ''}` +
    `&play_test_state=${playTestState}` +
    `&current_location=${locationId || ''}` +
    `&screen=${screen || ''}` +
    `&app_version=${app_version}` +
    `&r_role=${'none'}` +
    `&additional_comments=${userFeedback || 'none'}`;
  logForCrashlytics('apiLeaveQuestionReview fired in apiCalls' + url);

  logCustomAnalyticsEvent('leave_question_feedback', {
    userId,
    questionId,
    liked,
  });

  console.log({url});

  try {
    const response = await fetch(url);
    if (response.status < 300) {
      const result = await response?.json?.();
      console.log('------------------------------------');
      console.log('------------------------------------');
      console.log('review response', result);
      console.log('------------------------------------');
      console.log('------------------------------------');
      return result;
    }
  } catch (error: unknown) {
    return {error};
  }
};

interface ApiCESReviewParams {
  r_hunt?: string;
  r_app?: string;
  r_metric?: string;
  groupId?: string | null;
  userId?: string | null;
  eventId?: string | null;
  huntId?: string;
  additionalComments?: string;
  type?: string;
  additionalData?: Record<string, string | number | undefined | null>; // You might want to define a more specific type for this if possible.
  playTestState?: string;
}

export const apiCESReview = async ({
  r_hunt = '',
  r_app = '',
  r_metric = '',
  groupId = '',
  userId = '',
  eventId = '',
  huntId = '',
  additionalComments = '',
  type = 'regular',
  additionalData = {},
  playTestState = 'false',
}: ApiCESReviewParams) => {
  logForCrashlytics('apiCESReview fired in apiCalls');

  console.log({apiCESReview});

  logCustomAnalyticsEvent('leave_question_feedback', {
    userId,
    eventId,
    huntId,
  });

  const data = {
    user_id: userId,
    hunt_id: huntId,
    event_id: eventId,
    group_id: groupId,
    r_hunt: r_hunt,
    r_app: r_app,
    r_metric: r_metric,
    type: type,
    website_domain: 'app',
    play_test_state: playTestState,
    additional_comments: additionalComments || 'none',
    additional_data: JSON.stringify(additionalData),
  };

  const url = `${getBaseUrl()}/api/v1/hunts/add_hunt_review`;

  console.log({url, data});

  try {
    const response = await fetchWithParams(url, data);
    return response;
  } catch (error: unknown) {
    return {error};
  }
};

////////////////////////////////////////////////
/////////////////  voucher redemptions

export const apiCheckNamedVoucher = async (
  userId: string,
  voucherCode: string,
) => {
  logForCrashlytics('apiCheckNamedVoucher fired in apiCalls');

  const url = `${getBaseUrl()}/api/v1/app/vouchers/named_voucher`;
  const token = 'fd839e23-0ccc-4db4-bea2-ab92ba3f59fa';

  const params = {
    token,
    user_id: userId,
    voucher_code: voucherCode,
  };

  try {
    const result = await fetchWithParams(url, params);
    console.log({result});

    return result;
  } catch (error: unknown) {
    console.log(error);
  }
};

////////////////////////////////////////////////
/////////////////  community/daily challenges

export const getRecentTopPhotos = async () => {
  logForCrashlytics('getRecentTopPhotos fired in apiCalls');

  const url = `${getBaseUrl()}/api/v1/app/community/get_most_shared_photos`;
  const token = '1a20e933-4e14-478f-ae2f-0263711ac0c1';

  try {
    const result = await fetchWithParams(url, {token});

    return result && result.result;
  } catch (error: unknown) {
    console.log(error);
  }
};

export const getDailyChallenges = async (userId?: string) => {
  logForCrashlytics('getDailyChallenges fired in apiCalls');

  const url = `${getBaseUrl()}/api/v1/app/community/get_daily_challenges`;
  const token = 'ccaa1d94-7878-4c57-b4af-8bfc5044d0d5';

  try {
    const result = await fetchWithParams(url, {
      token,
      user_id: userId,
    });

    return result && result.result;
  } catch (error: unknown) {
    console.log(error);
  }
};

interface LeaveFeedbackParams {
  challenge_id: string;
  liked: boolean; // Assuming "liked" is a boolean. Update the type if it's different.
}

export const apiLeaveChallengeFeedback = async ({
  challenge_id,
  liked,
}: LeaveFeedbackParams) => {
  logForCrashlytics('apiLeaveChallengeFeedback fired in apiCalls');

  const url = `${getBaseUrl()}/api/v1/app/allChallenges/leave_feedback`;
  const token = 'ccaa1d94-7878-4c57-b4af-8bfc5044d0d5';

  try {
    const result = await fetchWithParams(url, {
      token,
      challenge_id,
      liked,
    });

    return result;
  } catch (error: unknown) {
    console.log(error);
  }
};

interface FavoriteLocationData {
  user_id: string;
  product_id: string;
  product_type: 'local_site' | 'wanderlist'; // If there are other possible product types, you can use a union type.
  favorited: boolean;
}
export const apiFavoriteLocation = async (data: FavoriteLocationData) => {
  logForCrashlytics('apiFavoriteLocation fired in apiCalls');

  const url = `${getBaseUrl()}/api/v1/users/product/favorited`;
  const token = 'ccaa1d94-7878-4c57-b4af-8bfc5044d0d5';

  try {
    console.log('the apiFavoriteLocation parameters being sent are', {
      ...data,
      token,
    });

    const result = await fetchWithParams(url, {
      ...data,
      token,
    });

    return result;
  } catch (error: unknown) {
    console.log(error);
  }
};

interface ApiCheckAnyChallengeCheckData {
  challenge_id?: string | null;
  photo_url?: string;
  shareUUID?: string | null; // Optional based on the input provided
  token?: string | null; // Optional since it has a default value
  location_id?: string | number | true | TJSONObject | TJSONArray;
  google_vision_api_version?: number | null; // Optional since it has a default value
  record_completion?: number | null; // Optional since it has a default value
  share_UUID?: string | null; // Optional based on the input provided
  points_earned?: number | null;
  challenge_page?: string | null; // Optional since it has a default value of null
  challenge_type?: string | null; // Optional since it has a default value of null
}

export const apiCheckAnyChallenge = async ({
  challenge_id,
  photo_url,
  token = '6af5a9e5-5bc5-45fb-a3e9-f2df6d48ae3b',
  location_id,
  google_vision_api_version = 1,
  record_completion = 1,
  share_UUID,
  points_earned,
  challenge_type = null,
  challenge_page = null,
}: ApiCheckAnyChallengeCheckData): Promise<onScoreResponse | null> => {
  const url = `${getBaseUrl()}/api/v1/app/community/check_any_lr_photo_challenge`;

  // adding in group id if null
  const user_id = getReduxState((state) => state?.user?.userId);
  const group_id = getReduxState((state) => state?.group?.info?.groupId);

  location_id =
    location_id || getReduxState((state) => state?.game_v2?.currentLocationId);

  challenge_id =
    challenge_id ||
    getReduxState((state) => state?.game_v2?.currentChallengeId) ||
    undefined;

  try {
    const result = await fetchWithParams(url, {
      challenge_id,
      photo_url,
      share_UUID,
      token,
      user_id,
      group_id,
      google_vision_api_version,
      record_completion,
      location_id,
      points_earned,
      challenge_page,
      challenge_type,
    });

    if (result) {
      return result as onScoreResponse;
    } else {
      return null;
    }
  } catch (error: unknown) {
    console.log(error);
  }
  return null;
};

interface LocalGuideData {
  userId: string | undefined;
  latitude?: number;
  longitude?: number;
  get_all: number;
  roundedLat: number | undefined;
  roundedLong: number | undefined;
}

export const apiGetLocalGuide = async ({
  userId,
  latitude,
  longitude,
}: LocalGuideData) => {
  logForCrashlytics('apiGetLocalGuide fired in apiCalls');

  const url = `${getBaseUrl()}/api/v1/get_local_guide_info_all`;
  const token = '6af5a9e5-5bc5-45fb-a3e9-f2df6d48ae3b';

  try {
    const result = await fetchWithParams(url, {
      token,
      user_id: userId,
      lat: latitude,
      long: longitude,
    });

    return result;
  } catch (error: unknown) {
    console.log(error);
  }
};

interface DailyChallengeData {
  userId: string;
  scheduled_id?: string | null;
  photo_url: string;
  shareUUID: string;
}

export const apiMarkDailyChallengeCompleted = async ({
  userId,
  scheduled_id = null,
  photo_url,
  shareUUID,
}: DailyChallengeData) => {
  logForCrashlytics('apiMarkDailyChallengeCompleted fired in apiCalls');

  logCustomAnalyticsEvent('daily_challenge_completed', {
    userId,
    scheduled_id,
    photo_url,
  });

  const url = `${getBaseUrl()}/api/v1/app/community/mark_daily_challenge_complete`;
  const token = '6af5a9e5-5bc5-45fb-a3e9-f2df6d48ae3b';

  try {
    const result = await fetchWithParams(url, {
      token,
      user_id: userId,
      scheduled_id,
      photo_url,
      shareUUID,
      google_vision_api_version: 1,
      autoPass: !Device.isDevice || __DEV__,
    });

    return result;
  } catch (error: unknown) {
    console.log(error);
  }
};
export const apiScheduleNextDayDailyChallengeNotification = async (
  userId: string,
) => {
  logForCrashlytics(
    'apiScheduleNextDayDailyChallengeNotification fired in apiCalls',
  );

  const url = `${getBaseUrl()}/api/v1/app_notifications/daily_challenge_notification`;

  try {
    const result = await fetchWithParams(url, {
      user_id: userId,
    });

    return result;
  } catch (error: unknown) {
    console.log(error);
  }
};

interface SocialCompletedData {
  userId: string;
  challengeType: string;
}

export const apiMarkSocialCompleted = async ({
  userId,
  challengeType,
}: SocialCompletedData) => {
  logForCrashlytics('apiMarkSocialCompleted fired in apiCalls');

  const url = `${getBaseUrl()}/api/v1/app/community/mark_daily_challenge_complete`;
  const token = '6af5a9e5-5bc5-45fb-a3e9-f2df6d48ae3b';

  console.log('marking social challenge down', {userId, challengeType});

  try {
    const result = await fetchWithParams(url, {
      token,
      user_id: userId,
      challenge_type: challengeType,
    });

    console.log('the result was ', {result});

    return result;
  } catch (error: unknown) {
    console.log(error);
  }
};

interface UpdateUserInfoParams {
  userId: string;
  gender?: string;
  custom_photo_url?: string;
  birthday?: string;
  latitude?: number;
  longitude?: number;
  home_zip_code?: string;
  contactPhone?: string;
}
export const apiUpdateUserInfo = async ({
  userId,
  gender,
  custom_photo_url,
  birthday,
  latitude,
  longitude,
  home_zip_code,
  contactPhone,
}: UpdateUserInfoParams) => {
  logForCrashlytics('apiUpdateUserInfo fired in apiCalls');

  const token = 'd889c33f-6b10-42ce-8ea6-414c981e2599';

  const params = {
    gender,
    custom_photo_url,
    birthday,
    home_zip_code,
    token,
    latitude,
    longitude,
    user_id: userId,
    contactPhone,
  };

  // Alert.alert(url, JSON.stringify(params));
  console.log({params});

  try {
    // Alert.alert(url, result)

    const userURL = `${getBaseUrl()}/api/v1/users/update_info`;

    const response = await fetchWithParams(userURL, params);

    return console.log('successfully updated ' + userId, {response});
  } catch (error: unknown) {
    console.log(error);
  }
};

////////////////////////////////////////////////
/////////////////  Getting Extended User Data

export const apiUpdateUserBadges = async () => {
  if (getAppType() == 'aa') {
    return false;
  }
  let userId = getReduxState((state) => state?.user?.userId);
  const groupId = getReduxState((state) => state?.group?.info?.groupId);
  logForCrashlytics('apiUpdateUserBadges fired in apiCalls');
  console.log('!!!!!!!!!!!!!!!!!!!!! update badges !!!!!!!!!!!!!!!!!!!!');

  const url = `${getBaseUrl()}/api/v1/users/super_data`;
  console.log({url});
  const token = 'f68efd8c-852d-4bd5-885c-bec2b99f0c1a';

  if (!userId) {
    userId = await mmkvStorage.getString('userId');
  }

  if (!userId) {
    return console.log('not signed in');
  }

  const params = {
    token,
    user_id: userId,
    group_id: groupId || null,
  };
  console.log({params});

  try {
    const result = await fetchWithParams(url, params);

    // console.log({result});
    return result;
  } catch (error: unknown) {
    console.error(error);
    if (__DEV__) {
      Alert.alert('Badges Api Error');
    }
  }
};

interface apiAdminEarnBadgeProps {
  user_id: string;
  badge_id: string;
}

export const apiAdminEarnBadge = async ({
  user_id,
  badge_id,
}: apiAdminEarnBadgeProps) => {
  const url = `${getBaseUrl()}/api/v1/admin_earn_badge`;

  const params = {
    user_id,
    badge_id,
  };

  try {
    const result = await fetchWithParams(url, params);
    console.log({result});
    return result;
  } catch (error: unknown) {
    console.log(error);
  }
};

export const apiSendPostCard = async (
  address: string,
  postCardData: TJSONValue,
  postCardURL: string,
) => {
  logForCrashlytics('apiSendPostCard fired in apiCalls');

  const url = `${getBaseUrl()}/api/v1/app_notifications/post_hunt_mailer/apiSendPostCard`;
  const token = 'f68efd8c-852d-4bd5-885c-besdfgsfdgfg';

  const params = {
    token,
    address,
    postCardData,
    postCardURL,
  };

  try {
    const result = await fetchWithParams(url, params);
    return result;
  } catch (error: unknown) {
    console.log(error);
  }
};

export const apiRateLocationChallenge = async (
  challengeId: string,
  liked: boolean,
) => {
  logForCrashlytics('apiRateLocationChallenge fired in apiCalls');

  const url = `${getBaseUrl()}/api/v1/app/hunt/location_challenges/rate`;
  const token = 'a598f56b-2306-4f9a-aeec-3211ffb4519a';

  const question_id = challengeId?.substring?.(2);

  const params = {
    token,
    question_id,
    liked,
  };

  try {
    await fetchWithParams(url, params);
  } catch (error: unknown) {
    console.log(error);
  }
};

interface TrackUserTransactionParams {
  type?: string;
  user_id: string;
  usage_user_id: string;
  group_id?: string | null;
  sch_voucher_code?: string | null;
  coins?: number;
  tickets?: number | null;
  theme?: string | null;
  groupon_voucher_code?: string | null;
  voucher_source?: string | null;
}

export const apiTrackUserTransaction = async ({
  type = '',
  user_id,
  usage_user_id,
  group_id = null,
  sch_voucher_code = null,
  coins = 0,
  tickets = 0,
  theme = null,
  groupon_voucher_code = null,
  voucher_source = null,
}: TrackUserTransactionParams) => {
  const params = {
    type,
    user_id,
    usage_user_id,
    sch_voucher_code,
    group_id,
    coins,
    tickets,
    theme,
    groupon_voucher_code,
    voucher_source,
  };

  const url = `${getBaseUrl()}/store/api/app/track_app_transaction`;

  console.log('apiTrackUserTransaction', {params, url});

  try {
    const result = await fetchWithParams(url, params);
    console.log({result});
    return result;
  } catch (error: unknown) {
    console.log(error);
    return {};
  }
};

export const sendAppFAQFeedback = (feedback_type: string, entry_id: string) => {
  const url = `${getBaseUrl()}/api/v1/help_send_feedback?feedback=${feedback_type}&entry_id=${entry_id}`;
  console.log('sendAppFAQFeedback', {url});
  fetchWithParams(url);
};

export const getAppFaqData = async () => {
  const url = `${getBaseUrl()}/api/api_faqs_v1/get_app_faqs`;

  console.log('apiTrackUserTransaction', {url});

  try {
    const result = await fetchWithParams(url);
    console.log({result});
    return result?.Questions;
  } catch (error: unknown) {
    console.log(error);
    return {};
  }
};

export const schedulePushNotificationForUser = async ({
  title = '',
  body = '',
  topic = 'error',
}) => {
  const url = `${getBaseUrl()}/api/v1/app_notifications/send_instant_push_notification`;

  const data = {title, body, topic};

  console.log('send_instant_push_notification', {url, data});

  try {
    const result = await fetchWithParams(url, data);
    console.log({result});
    return result?.Questions;
  } catch (error: unknown) {
    console.log(error);
    return {};
  }
};

export const apiSetQuestion = async (data: Record<string, unknown>) => {
  const url = `${getBaseUrl()}/api/writers/set_question`;

  const params = {
    data: {
      ...data,
      long: data?.lng,
    },
  };

  console.log('apiSetQuestion', {params, url});

  try {
    const response = await fetchWithParams(url, params);
    console.log('the response is ', response);
    return response;
  } catch (error: unknown) {
    console.log('the locations could not be fetched', error);
  }
};

interface SignGuessBookParams {
  location_id: string; // or number, if the IDs are numeric
  content: string;
  submitted_by_user_id: string; // or number, if the IDs are numeric
  submitted_by_user_name: string;
  submitted_by_user_image: string | null;
}
export const apiSignGuestBook = async (data: SignGuessBookParams) => {
  const url = `${getBaseUrl()}/api/writers/apiSignGuestBook`;

  const params = {
    ...data,
  };

  console.log('apiSignGuestBook', params);

  try {
    const response = await fetchWithParams(url, params);
    console.log('the response is ', response);
    return response;
  } catch (error: unknown) {
    console.log('the locations could not be fetched', error);
  }
};

interface GetLocalGuideDetailsParams {
  location_id: string;
  user_id: string;
}

export const apiGetLocalGuideDetails = async (
  data: GetLocalGuideDetailsParams,
) => {
  const url = `${getBaseUrl()}/api/writers/apiGetLocalGuideDetails`;

  const params = {
    ...data,
  };

  console.log('apiGetLocalGuideDetails', params);

  try {
    const response = await fetchWithParams(url, params);
    console.log('the response is ', response);
    return response;
  } catch (error: unknown) {
    console.log('the locations could not be fetched', error);
  }
};

export const apiSetLocation = async (data: TJSONValue) => {
  const url = `${getBaseUrl()}/api/writers/set_location`;

  delete data['location_id'];

  const params = {
    data: {
      ...data,
      long: data?.lng,
    },
  };
  delete params.data.question_count;
  delete params.data.regular_question_count;
  delete params.data.local_question_count;
  delete params.data.corporate_question_count;
  delete params.data.infoType;
  delete params.data.editType;
  delete params.data.editMode;
  delete params.data.question_order;
  delete params.data.rank;
  delete params.data.lng;

  console.log('the apiSetLocation parameters being sent are', params);

  try {
    const response = await fetchWithParams(url, params);
    console.log('the response is ', response);
    return response;
  } catch (error: unknown) {
    console.log('the locations could not be fetched', error);
  }
};

interface ApiCreateWanderlistParams {
  newWanderListPhoto: string;
  newWanderListTitle: string;
  newWanderListLocations: TNewWanderLocation[];
  newWanderListDescription: string;
  userId?: string;
  lat: number;
  lng: number;
}

export const apiCreateWanderlist = async (data: ApiCreateWanderlistParams) => {
  const url = `${getBaseUrl()}/api/v1/user_create_wanderlist`;

  const params = {
    data: {
      ...data,
    },
  };

  console.log('the params being sent for apiCreateWanderlist are', params);

  try {
    const response = await fetchWithParams(url, params);
    console.log('the apiCreateWanderlist response is ', response);
    return response;
  } catch (error: unknown) {
    console.log('the locations could not be fetched', error);
  }
};

export const logPhoneCallAttempt = (
  source: string,
  user_phone_number: string,
  number_dialed: string,
) => {
  const userId = getReduxState((state) => state?.user?.userId);
  const current_screen = getReduxState((state) => state?.current_screen);
  const url = `api/v1/lr_support_phone_call_requests`;
  const params = {
    source,
    user_phone_number,
    number_dialed,
    current_page: current_screen?.currentScreen,
    previous_page: current_screen?.previousScreen,
    previous_previous_page: current_screen?.previousPreviousScreen,
    user_id: userId,
  };
  console.log('sendAppFAQFeedback', {url, params});
  fetchWithParams(url, params);
};

interface ApiChargeCardParams {
  user_id: string | number; // Adjust based on your requirements
  email: string;
  first_name: string;
  card_number: string;
  exp_month: number; // Assuming it's a two-digit number
  exp_year: number; // Assuming it's a four-digit number
  cvc: number | string; // Assuming it's a three or four-digit number
  total: number;
  ticket_quantity: number;
  theme: string; // Adjust type if theme is not a string
  isMembership: boolean;
  stripe_customer_id?: string | null; // Adjust based on your requirements
  makeFree?: boolean | null; // used to make free for admin
  trial_period_days?: number;
}
export const apiChargeCard = async ({
  user_id,
  email,
  first_name,
  card_number,
  exp_month,
  exp_year,
  cvc,
  total,
  ticket_quantity,
  theme,
  isMembership,
  stripe_customer_id = null,
  makeFree = false,
  trial_period_days = 7,
}: ApiChargeCardParams) => {
  const params = {
    user_id,
    email,
    first_name,
    card_number,
    exp_month,
    exp_year,
    cvc,
    total,
    ticket_quantity,
    stripe_customer_id,
    theme,
    isMembership,
    utm_source: mmkvStorage.getString('utm_source'),
    utm_medium: mmkvStorage.getString('utm_medium'),
    utm_campaign: mmkvStorage.getString('utm_campaign'),
    utm_term: mmkvStorage.getString('utm_term'),
    trial_period_days,
  };

  let url = `${getBaseUrl()}/store/api/app/create_charge_from_card`;

  if (__DEV__ || makeFree) {
    url += '?updated_app_test=1';
  }

  console.log('charging card', {url, params});
  const chargeResult = await fetchWithParams(url, params);

  return chargeResult;
};
