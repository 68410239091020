import React, {useEffect, useState} from 'react';
import {ScrollView, Text, TouchableOpacity, View} from 'react-native';
import ExpoImage from 'src/Utils/ExpoImage';

// utils
import {selectCharacter} from 'src/Utils/huntFunctions';

// components
import {BodyText, ContinueButton} from 'src/Modules/CommonView';
import {navigationRef} from 'src/Nav/navigationHelpers';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import constants from 'src/constants';
import {RootState} from 'src/Redux/reducers/rootReducer';
import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import {TCharacter} from 'src/types/TTheme';
import {TGroupPlayer} from 'src/types/TGroupInfo';

interface CharacterButtonProps {
  chooseCharacter: (_characterObj: TCharacter) => void;
  characterObj: TCharacter;
  selectedCharacter: TCharacter | null;
}

const CharacterButton: React.FC<CharacterButtonProps> = ({
  chooseCharacter,
  characterObj,
  selectedCharacter,
}) => {
  const {source, character, description} = characterObj || {};

  // changing the color of the selected character box based on the character selected
  const isSelected = selectedCharacter?.character === character;
  const backgroundColor = isSelected
    ? constants?.color?.orange
    : constants?.color?.gray1;
  const shadowColor = isSelected ? '#CD681B' : constants?.color?.gray2;
  const textColor = isSelected ? 'white' : constants?.color?.gray3;

  return (
    <TouchableOpacity onPress={() => chooseCharacter(characterObj)}>
      <View
        style={{
          width: 160,
          height: 220,
          margin: 10,
          backgroundColor,
          borderRadius: 10,
          padding: 15,
          alignItems: 'center',
          shadowColor,
          shadowOffset: {
            width: 3,
            height: 3,
          },
          shadowOpacity: 100,
          shadowRadius: 1,
          elevation: 5,
        }}>
        <View
          style={{
            // New style for the white circle background
            backgroundColor: 'white',
            borderRadius: 35,
            width: 70,
            height: 70,
            justifyContent: 'center',
            alignItems: 'center',
            shadowColor: shadowColor,
            shadowOffset: {
              width: 3,
              height: 3,
            },
            shadowOpacity: 100,
            shadowRadius: 1,
            elevation: 5,
          }}>
          {!!source && (
            <ExpoImage
              style={{
                height: 58,
                width: 58,
              }}
              source={{uri: cloudFlareOptimizeImage(source)}}
              resizeMode={'cover'}
            />
          )}
        </View>
        <Text
          style={{
            color: textColor,
            fontFamily: constants?.font.circular,
            fontSize: 12,
            marginVertical: 5,
            fontWeight: 'bold',
            textAlign: 'center',
          }}>
          {character}
        </Text>
        <Text
          style={{
            color: textColor,
            fontFamily: constants?.font.circular,
            fontSize: 9.5,
            marginVertical: 2,
            textAlign: 'center',
          }}>
          {description}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

const CharacterSelection: React.FC = () => {
  const userId = useTypedSelector((state: RootState) => state.user?.userId);

  const groupId = useTypedSelector((state) => state.group?.info?.groupId);
  const theme = useTypedSelector((state) => state.group?.info?.theme);

  const players = useTypedSelector(
    (state) => state.group?.info?.players || {},
    deepEqualityCheck,
  );

  const themes = useTypedSelector((state) => state.themeInfo?.themes);
  const characters = useTypedSelector((state) => state.themeInfo?.characters);

  const [selectedCharacter, setSelectedCharacter] = useState<TCharacter | null>(
    null,
  );

  //setting the default character to the first character in the theme
  useEffect(() => {
    if (theme && themes && characters) {
      const defaultCharacterKey = Object.values(themes[theme].characters)[0];
      const defaultCharacter = characters[defaultCharacterKey];
      setSelectedCharacter(defaultCharacter);
    }
  }, [theme, themes, characters]);

  const playersOnDevice = Object.values(players).filter((player) => {
    return player.userId == userId || player.parentUserId == userId;
  });

  const handleChooseCharacter = (selectedCharacter: TCharacter) => {
    setSelectedCharacter(selectedCharacter);
  };

  const handleCharacterSelected = async (player: TGroupPlayer) => {
    console.log('handleCharacterSelected fired', {player, selectedCharacter});

    if (!selectedCharacter) {
      return navigationRef.simpleAlert('Please select a character');
    }

    if (!userId) {
      return navigationRef.simpleAlert('Please sign in');
    }

    await selectCharacter(
      selectedCharacter?.character,
      selectedCharacter?.character,
      player?.userId || 'error',
      userId,
    );
    setSelectedCharacter(null);

    if (playersOnDevice.every((devicePlayer) => devicePlayer.character)) {
      console.log('Character Selection: navigate to main game stack called');
      return navigationRef.navigate(
        'MainGameStack',
        null,
        'character selection 1',
      );
    }
  };

  console.log({playersOnDevice});

  const currentPlayer =
    (playersOnDevice &&
      playersOnDevice?.length > 0 &&
      playersOnDevice?.find?.((devPlayer) => !devPlayer.character)) ||
    false;

  console.log({playersOnDevice, currentPlayer});

  if (!groupId || !currentPlayer) {
    console.log('showing Loading Your Hunt because', {
      groupId,
      currentPlayer,
      playersOnDevice,
      userId,
    });

    return (
      <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
        <BodyText
          text={'Loading Your Hunt'}
          textStyle={{textAlign: 'center', marginBottom: 0}}
        />
        <BodyText
          text={`Join Code: ${groupId}`}
          textStyle={{textAlign: 'center', marginBottom: 10}}
        />
        <CustomActivityIndicator />
        <ContinueButton
          text={'Reload'}
          width={150}
          containerStyle={{marginTop: 100}}
          handlePress={() => {
            navigationRef.navigate(
              'MainGameStack',
              null,
              'character selection 3',
            );
          }}
        />
      </View>
    );
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: constants?.color?.white,
      }}>
      <ScrollView style={{flex: 1}}>
        <View
          testID="chooseYourCharacter"
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text
            style={{
              color: constants?.color?.gray3,
              fontFamily: constants?.font.circular,
              fontSize: 18,
              marginVertical: 30,
              textAlign: 'center',
            }}>
            {`${currentPlayer.firstName}! Choose Your Character`}
          </Text>
        </View>
        <View
          style={{
            justifyContent: 'center',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'center',
          }}>
          {!!theme &&
            Object.values(themes![theme].characters)?.map?.((key) => {
              const character = characters![key] || {};
              return (
                <CharacterButton
                  key={character.character}
                  characterObj={character}
                  chooseCharacter={() => {
                    handleChooseCharacter(character);
                  }}
                  selectedCharacter={selectedCharacter}
                />
              );
            })}
        </View>
      </ScrollView>
      <View
        style={{
          position: 'absolute',
          left: 20,
          right: 20,
          bottom: 50,
          flex: 1,
          height: 50,
        }}>
        {!!selectedCharacter && (
          <ContinueButton
            background={constants?.color?.orange}
            text={'Pick Character'}
            handlePress={() => {
              handleCharacterSelected(currentPlayer);
            }}
          />
        )}
      </View>
    </View>
  );
};

export default CharacterSelection;
