import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState, useEffect} from 'react';
import moment from 'moment-timezone';
import {
  Alert,
  Platform,
  Image,
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  Linking,
  ImageBackground,
  TextInput,
  Share,
} from 'react-native';

import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import GetDirectionsCard from 'src/Modules/GetDirectionsCard';

import {BodyText} from 'src/Modules/CommonView';
import QRCode from 'react-native-qrcode-svg';

import ExpoImage from 'src/Utils/ExpoImage';
import AddToDeviceModal from 'src/Modules/CommonGame/AddToDeviceModal';

//////////////////// utils
import {apiUpdateUserBadges} from 'src/Utils/apiCalls';

import {updateDataAtPath, createGroupShareLink} from 'src/Utils/fireUtils';

import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';

import CheckBox from 'src/Modules/Native/CheckBox';
import {TouchableWithoutFeedback} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import {socialShare} from 'src/Utils/shareHelper';

import {Fragment} from 'react';

import {ContinueButton} from 'src/Modules/CommonView/Buttons';
import CallSupportLine from 'src/HeaderComponent/CallSupportLine';

import {navigationRef} from 'src/Nav/navigationHelpers';
import {parseHTML} from 'src/Utils/dataHelper';

import {useUserId} from 'src/Hooks/reduxHooks';
import GameLobbyPlayers from 'src/ScavengerHunt_v2/Lobby/GameLobbyPlayers';
import {addChildUser, startHunt, updateTeamName} from 'src/Utils/huntFunctions';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import {HtmlBlock} from 'src/Modules/CommonView/Text';
import {setDataAtPath} from 'src/Utils/fireUtils';
import {SafeAreaView} from 'react-native-safe-area-context';
import {SimpleHeaderBar} from 'src/HeaderComponent/HeaderComponents';
import {LinearGradient} from 'expo-linear-gradient';
const buoy = require('src/Images/Icons/lifebuoy.png');

const GameLobby: React.FC = () => {
  const {width} = useWindowDimensionsSafe();

  const userId = useUserId();
  const easyUserLevelJoinCode = useTypedSelector(
    (state) => state?.user?.info?.easyUserLevelJoinCode,
  );

  const playTestState = useTypedSelector(
    (state) => state.app_info?.playTestState,
  );

  const timerLimitMinutes = useTypedSelector(
    (state) => state.game_v2?.timerLimitMinutes,
  );

  const [shouldStartFromNameModal, setShouldStartFromNameModal] =
    useState<boolean>(false);

  console.log('GameLobby', {shouldStartFromNameModal});
  const [showAddPlayers, setShowAddPlayers] = useState<boolean>(false);
  const view = 'lobby';
  const [paidTickets, setPaidTickets] = useState<boolean>(true);

  const players = useTypedSelector(
    (state) => state.group?.info?.players,
    deepEqualityCheck,
  );

  const createdBy = useTypedSelector((state) => state.group?.info?.createdBy);
  const eventId = useTypedSelector((state) => state.group?.info?.eventId);
  const groupId = useTypedSelector((state) => state.group?.info?.groupId);
  const discoverable = useTypedSelector(
    (state) => state.group?.info?.discoverable ?? false,
  );
  const easyGroupLevelJoinCode = useTypedSelector(
    (state) => state.group?.info?.easyGroupLevelJoinCode,
  );
  const description = useTypedSelector(
    (state) => state.group?.info?.description,
  );
  const teamName = useTypedSelector((state) => state.group?.info?.teamName);
  const lat = useTypedSelector((state) => state.group?.info?.lat);
  const long = useTypedSelector((state) => state.group?.info?.long);
  const huntType = useTypedSelector((state) => state.group?.info?.huntType);
  const huntPhoto = useTypedSelector((state) => {
    if (
      state.group?.info?.huntPhoto?.includes('photos.letsroam.com') ||
      state.group?.info?.huntPhoto?.includes('photos.scavengerhunt.com') ||
      state.group?.info?.huntPhoto?.includes('firebasestorage.googleapis.com')
    ) {
      return state.group?.info?.huntPhoto;
    } else if (!!state.group?.info?.huntPhoto) {
      return (
        'https://photos.letsroam.com/scavenger_hunt_locations/' +
        state.group?.info?.huntPhoto
      );
    } else {
      return '';
    }
  });

  const huntStarted = useTypedSelector(
    (state) => state?.group?.info?.huntStarted,
  );

  const theme = useTypedSelector((state) => state?.group?.info?.theme);
  const isFree = useTypedSelector((state) => state?.group?.info?.isFree);
  const passId = useTypedSelector((state) => state?.group?.info?.passId);
  const indoor_hunt = useTypedSelector(
    (state) => state?.group?.info?.indoor_hunt,
  );
  const routeId = useTypedSelector((state) => state?.group?.info?.routeId);
  const startingLocation = useTypedSelector(
    (state) => state?.group?.info?.startingLocation,
  );
  const huntName = useTypedSelector((state) => state?.group?.info?.huntName);
  const eventStartTime = useTypedSelector(
    (state) => state?.group?.info?.eventStartTime,
  );

  useEffect(() => {
    try {
      apiUpdateUserBadges();
    } catch (error: unknown) {
      console.log({error});
    }
  }, [Object.keys(players || {})?.length, huntStarted, teamName]);

  const dateTimeEventCreated = useTypedSelector(
    (state) =>
      state?.group?.info && 1 * state?.group?.info?.dateTimeEventCreated,
  );

  useEffect(() => {
    if (!huntStarted && eventId) {
      const checkEventStale = () => {
        console.log('setting staleness checker');
        const currentTime = new Date().getTime();
        const timeSinceEventCreatedMS =
          currentTime - (dateTimeEventCreated || currentTime);
        console.log('checking if event is stale', {timeSinceEventCreatedMS});
        let cutoff;
        if (__DEV__) {
          cutoff = 1 * 60 * 1000; // 1 minute in milliseconds
        } else {
          cutoff = 10 * 60 * 1000; // 1 hour in milliseconds
        }

        if (timeSinceEventCreatedMS > cutoff) {
          console.log_on_production?.('event is stale, deleting');
          setDataAtPath(
            `scavengerhunt/groups/${groupId || 'error'}/dateTimeEventCreated`,
            Date.now(),
          );
        }
      };

      const intervalId = setInterval(checkEventStale, 10000); // Check every 10 seconds

      // Clear the interval when the component is unmounted
      return () => {
        console.log('clearing staleness checker');
        clearInterval(intervalId);
      };
    }
  }, [eventId, huntStarted, dateTimeEventCreated, groupId]);

  const handleNameSubmit = (
    newName: string,
    closeModal: () => void,
    forced: boolean,
  ) => {
    console.log('handle name submit', {
      newName,
      shouldStartFromNameModal,
    });
    if (!newName) {
      return Alert.alert('A team name is required! Think of something fun!');
    }

    if (groupId) {
      updateTeamName(groupId, newName);
    }
    closeModal();

    if (shouldStartFromNameModal || forced) {
      setShouldStartFromNameModal(false);
      if (groupId) {
        startHunt(groupId);
      }
    }
  };

  const showChangeName = (startHuntAfter = false) => {
    console.log('showChangeName', {teamName});
    navigationRef.navigate(
      'ConfirmModal',
      {
        titleText: `What do you want to name your group?`,
        titleBodyText: startHuntAfter
          ? 'You are almost there, before you get started pick a fun and exciting name that will help your group stand out from the pack!'
          : 'Pick a fun and exciting name that will help your group stand out from the pack!',
        confirmText: 'Set Team Name',
        showPrompt: true,
        maxLength: 40,
        placeholder: 'Your new team name',
        cancelText: 'Cancel',
        initialPromptText: teamName == "Your Team's Name" ? '' : teamName,
        handleConfirm: (promptText, __, closeModal) => {
          promptText &&
            handleNameSubmit(promptText, closeModal, startHuntAfter);
        },
      },
      'ScavengerHunt_v2/Lobby/GameLobby.tsx',
    );
  };

  const handleStartPress = () => {
    console.log('handle start hunt');
    if (teamName == `Your Team's Name`) {
      console.log('here 1');
      setShouldStartFromNameModal(false);
      return showChangeName(true);
    }

    const items = [
      {
        heading: '🗺️ Are you ready to start your hunt?',
        description: 'You are about to start your adventure',
      },
      {
        heading: '📍 Get Ready At The Starting Location',
        description: 'Make sure your team is together and ready to go',
      },
      {
        heading: `⏳ You'll Start With ${
          timerLimitMinutes || 90
        } Minutes On The Timer`,
        description:
          'When the hunt timer ends, your hunt is over. You can pause at any time in the game menu to explore locations.',
      },
    ];

    navigationRef.navigate(
      'ConfirmModal',
      {
        handleConfirm: async (_, __, handleDone) => {
          if (teamName == `Your Team's Name`) {
            console.log('here 1');
            setShouldStartFromNameModal(true);
            showChangeName(true);
          } else {
            console.log('here 2');
            if (groupId) {
              startHunt(groupId);
            }
          }

          handleDone();
        },
        titleText: isHybridHunt ? 'Start your event' : null,
        titleBodyText: isHybridHunt
          ? 'Please confirm that you want to start the event NOW for your whole team.'
          : null,
        confirmText: `Start ${isHybridHunt ? 'Event' : 'Hunt'} Now`,
        cancelText: 'Later',
        confirmColor: constants?.color?.orange,
        cancelColor: constants?.color?.blue,
        items,
      },
      'ScavengerHunt_v2/Lobby/GameLobby.tsx',
    );
  };

  useEffect(() => {
    // createGroupShareLink

    if (!teamName || teamName == `Your Team's Name`) {
      showChangeName(false);
    }

    const forceUpdate = false;
    console.log({easyGroupLevelJoinCode});
    if (!easyGroupLevelJoinCode || forceUpdate) {
      console.log('creating a easyGroupLevelJoinCode', {
        code: groupId,
        groupId,
      });
      createGroupShareLink({code: groupId, groupId});
    }
    if (!easyUserLevelJoinCode || forceUpdate) {
      console.log('creating a easyUserLevelJoinCode', {
        code: userId,
        userId,
      });
      userId && createGroupShareLink({code: userId, userId});
    }
    apiUpdateUserBadges();
  }, []);

  const tickets = useTypedSelector(
    (state) => state.user?.info?.tickets?.[theme || 'Standard'] || 0,
  );

  const isHybridHunt = useTypedSelector((state) =>
    [
      'coworker_feud',
      'virtual_coworker_feud',
      'escape_room',
      'murder_mystery',
      'virtual_office_games',
    ].includes(state.group?.info?.huntType || ''),
  );

  const checkIfGroupNeedsTicketsToJoin = () => {
    let groupNeedsTicketsToJoin =
      playTestState == false &&
      !eventId &&
      !isFree &&
      !theme?.includes?.('home');

    const isPass = passId;

    if (isFree || isPass) {
      groupNeedsTicketsToJoin = false;
    }

    if (indoor_hunt) {
      groupNeedsTicketsToJoin = false;
    }
    return groupNeedsTicketsToJoin;
  };

  /// new ticket purchase functionality
  const navigateToTicketsModal = () => {
    navigationRef.navigate(
      'PurchaseModal',
      {
        forcedTheme: theme,
        handleDone: () => {
          setShowAddPlayers(true);
        },
      },
      'ScavengerHunt_v2/Lobby/GameLobby.tsx',
    );
  };

  const addPlayerToYourDevice = async () => {
    const groupNeedsTicketsToJoin = checkIfGroupNeedsTicketsToJoin();

    if (!!groupNeedsTicketsToJoin && (!tickets || tickets < 1)) {
      return navigateToTicketsModal();
    }

    setShowAddPlayers(true);
  };

  const shareInviteCode = (displayedCode: string, displayedLink: string) => {
    console.log({displayedLink});
    Share.share({
      // url: displayedLink,
      message:
        `Join my scavenger hunt by entering this code ${displayedCode} in the Let's Roam App link on your phone or follow this link: ` +
        displayedLink,
    });
  };

  const toggleType = () => {
    console.log('toggling add players modal');

    if (!tickets || tickets < 1) {
      Alert.alert(
        'You Need Tickets',
        'You need tickets to let people join with your tickets.',
        [
          {
            text: 'Purchase Tickets',
            onPress: () => {
              navigateToTicketsModal();
            },
          },
          {
            text: 'Cancel',
          },
        ],
      );
    } else {
      setPaidTickets(!paidTickets);
    }
  };

  const toggleDiscoverable = async () => {
    console.log('toggleDiscoverable', {discoverable});
    updateDataAtPath(
      `scavengerhunt/groups/${groupId}/discoverable`,
      !discoverable,
    );
  };

  if (!teamName || teamName == `Your Team's Name`) {
    showChangeName();
  }

  const joinCode = groupId;
  const prepaidJoinCode = userId;

  const formattedStartingLocation =
    startingLocation != null
      ? startingLocation
          .replace(/\d{5},\sU[^>]+/gi, '')
          .replace(/\sUSA/, '')
          .replace(/\sUnited\states/, '')
          .replace(/[,;]$/, '')
          .replace(/\s+$/, '')
      : '';

  const showBegin = (players && !players[createdBy as string]) || eventId;

  let displayedCode = joinCode;
  let displayedLink = joinCode;
  let checked = false;
  if (
    prepaidJoinCode &&
    checkIfGroupNeedsTicketsToJoin() &&
    tickets > 0 &&
    paidTickets
  ) {
    displayedCode = prepaidJoinCode;
    displayedLink = prepaidJoinCode;
    checked = true;
  }

  let qrCode = `https://www.letsroam.com/scavenger_hunt_app?app_redirect=1&join_code=${displayedCode}`;

  const parsedDescription = parseHTML(description || '');

  let activity_description = 'Scavenger Hunt';
  if (huntType == 'barHunt') {
    activity_description = 'Bar Hunt';
  } else if (huntType == 'artWalk') {
    activity_description = 'Art Walk Scavenger Hunt';
  } else if (huntType == 'audioTour') {
    activity_description = 'Audio Tour';
  } else if (huntType == 'virtual_office_games') {
    activity_description = 'VIRTUAL OFFICE GAMES';
  } else if (huntType == 'murder_mystery') {
    activity_description = 'MURDER MYSTERY';
  } else if (huntType == 'escape_room') {
    activity_description = 'ESCAPE ROOM';
  } else if (huntType == 'coworker_feud') {
    activity_description = 'COWORKER FEUD';
  }

  const companyURL = useTypedSelector(
    (state) => state.event?.info?.companyURL || '',
  );
  const corporateBrandingUrl = useTypedSelector(
    (state) => state.game_v2?.corporateBrandingUrl || '',
  );

  let logoURL;
  let clearbitLogoURL;

  if (window?.location?.href?.includes?.('isDemo')) {
    const urlParams = new URLSearchParams(window.location.search);
    const companyUrlParams = urlParams.get('companyURL');
    clearbitLogoURL = `https://logo.clearbit.com/${companyUrlParams}?size=800`;
  } else if (companyURL) {
    clearbitLogoURL = companyURL
      ? `https://logo.clearbit.com/${companyURL}?size=800`
      : '';
  }

  const logoURI = corporateBrandingUrl || clearbitLogoURL;

  if (logoURI) {
    logoURL = logoURI ? {uri: logoURI} : null;
  }

  console.log({qrCode});

  return (
    <SafeAreaView
      testID={`route-${routeId || 'no-route-id'}`}
      style={{
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
        justifyContent: 'flex-start',
      }}>
      <SimpleHeaderBar />
      <CallSupportLine />
      {
        //////////////////////////////////////////////////////////////////
        /////////////////////  PLAYERS  //////////////////////////////////
        //////////////////////////////////////////////////////////////////
      }
      {view === 'lobby' && !!isHybridHunt && (
        <ScrollView
          testID="lobbyScroll"
          contentContainerStyle={{backgroundColor: '#F9F7F2'}}>
          <View
            style={{
              width: '100%',
              maxWidth: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <ImageBackground
              source={{uri: cloudFlareOptimizeImage(huntPhoto) || ''}}
              style={{width, height: 250}}>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'absolute',
                  top: 8,
                  left: 24,
                }}></View>
              <LinearGradient
                colors={['#F9F7F2', 'rgba(249, 247, 242, 0.00)']}
                start={{x: 0, y: 1}}
                end={{x: 0, y: 0}}
                style={{
                  position: 'absolute',
                  bottom: 0,
                  height: '50%',
                  width: '100%',
                }}>
                <BodyText
                  testID="lobbyHuntType"
                  text={activity_description || ''}
                  textStyle={{
                    color: constants.color.orange,
                    fontFamily: 'JakartaBold',
                    fontSize: 11,
                    lineHeight: 11,
                    marginTop: 0,
                    marginLeft: 0,
                    letterSpacing: 1.1,
                    position: 'absolute',
                    bottom: 8,
                    left: 24,
                  }}
                />
              </LinearGradient>
            </ImageBackground>
            <View style={{paddingHorizontal: 24, width: '100%'}}>
              <BodyText
                text={huntName || ''}
                textStyle={{
                  color: constants.color.black,
                  fontFamily: 'JakartaBold',
                  fontSize: 28,
                  lineHeight: 28,
                  marginTop: 12,
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
              <BodyText
                text={parsedDescription || ''}
                textStyle={{
                  color: constants.color.black,
                  fontFamily: 'Jakarta',
                  fontSize: 15,
                  lineHeight: 21,
                  marginTop: 12,
                  marginLeft: 0,
                  marginRight: 0,
                }}
              />
            </View>

            <View
              style={{
                backgroundColor: constants?.color?.white,
                borderRadius: 10,
                marginTop: 12,
              }}>
              <TouchableOpacity
                style={{
                  borderBottomColor: constants?.color?.gray3,
                  paddingBottom: 3,
                  minWidth: 250,
                  marginTop: 0,
                }}
                onPress={() => showChangeName()}>
                <Text
                  style={{
                    color: constants?.color?.gray3,
                    fontFamily: constants?.font.circular,
                    fontSize: 20,
                    fontWeight: '700',
                    marginLeft: 5,
                    marginTop: 10,
                    textAlign: 'center',
                  }}>
                  <Text testID="teamName">{`Your Team: ${teamName}`}</Text>
                  <Text style={{marginLeft: 20}}>
                    <Icon
                      name={'pencil'}
                      size={16}
                      color={constants?.color?.gray3}
                    />
                  </Text>
                </Text>
              </TouchableOpacity>
              <GameLobbyPlayers isModal={false} />
            </View>

            <View
              style={{
                marginTop: 12,
                width: '100%',
                maxWidth: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                paddingHorizontal: 20,
              }}>
              <ImageBackground
                style={{
                  width: '100%',
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: constants?.color?.teal,
                  paddingHorizontal: 35,
                  paddingBottom: 20,
                }}
                source={require('src/Images/stylized_bg.png')}>
                <BodyText
                  color={constants?.color?.white}
                  testID={'joinHeader'}
                  text="Your Invite Code"
                  textStyle={{
                    width: '100%',
                    paddingTop: 10,
                    marginHorizontal: 0,
                    textAlign: 'left',
                    fontSize: 20,
                    fontFamily: 'JakartaBold',
                    lineHeight: 26,
                  }}
                />
                <BodyText
                  color={constants?.color?.white}
                  textStyle={{
                    marginHorizontal: 0,
                    paddingBottom: 10,
                    marginBottom: 10,
                    textAlign: 'left',
                    width: '100%',
                    fontSize: 14,
                    fontFamily: 'Jakarta',
                    lineHeight: 20,
                  }}
                  text={
                    <Text>
                      Have your friends click Join on the home screen and enter
                      your invite code.
                    </Text>
                  }
                />
                <View style={{alignItems: 'center', gap: 10}}>
                  <TextInput
                    editable={false}
                    placeholder="Q2134D2"
                    testID={'inviteCode'}
                    value={`${displayedCode?.toUpperCase?.()}` || ''}
                    autoCorrect={false}
                    underlineColorAndroid={'transparent'}
                    allowFontScaling={false}
                    blurOnSubmit
                    style={{
                      width: 280,
                      padding: 12,
                      backgroundColor: constants?.color?.white,
                      borderRadius: 8,
                      color: constants?.color?.gray2,
                      textAlign: 'center',
                      ...(Platform.OS == 'web' ? {padding: 10} : {}),
                    }}
                  />
                </View>
              </ImageBackground>
              {playTestState == false && (
                <TouchableOpacity
                  style={{
                    alignItems: 'center',
                    width: '100%',
                  }}
                  onPress={() => {
                    toggleDiscoverable();
                  }}>
                  <ImageBackground
                    style={{
                      width: '100%',
                      borderBottomLeftRadius: 10,
                      borderBottomRightRadius: 10,
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: '#4F8584',
                      paddingBottom: 20,
                      flexDirection: 'row',
                    }}
                    source={require('src/Images/stylized_bg.png')}>
                    <CheckBox
                      onCheckColor="#4F8584"
                      tintColor="white"
                      onTintColor="white"
                      onFillColor="white"
                      value={discoverable}
                      onValueChange={() => toggleDiscoverable()}
                      style={{width: 15, height: 15, marginTop: 10}}
                    />
                    <BodyText
                      color={constants?.color?.white}
                      testID={'joinHeader'}
                      text="Activate Easy Join to make your group discoverable to nearby devices."
                      textStyle={{
                        maxWidth: 200,
                        paddingTop: 10,
                        textAlign: 'center',
                        fontSize: 14,
                        fontFamily: 'JakartaBold',
                        lineHeight: 16,
                      }}
                    />
                  </ImageBackground>
                </TouchableOpacity>
              )}
            </View>

            {!playTestState && (
              <View
                style={{
                  paddingHorizontal: 24,
                  marginBottom: 55,
                }}>
                <View
                  style={{
                    backgroundColor: constants?.color?.white,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginHorizontal: 12,
                    marginTop: 12,
                    paddingVertical: 20,
                    gap: 20,
                    paddingHorizontal: 20,
                    borderRadius: 8,
                  }}>
                  <View
                    style={{
                      flexDirection: 'column',
                      flex: 1,
                      gap: 10,
                      alignItems: 'flex-start',
                      justifyContent: 'space-around',
                    }}>
                    <Text style={{fontSize: 20, fontFamily: 'JakartaBold'}}>
                      QR Code
                    </Text>
                    <Text style={{fontSize: 14, fontFamily: 'Jakarta'}}>
                      Your friends can also scan this QR code to join your team
                      and take part with you.
                    </Text>
                  </View>
                  <QRCode
                    value={qrCode}
                    logo={require('src/Images/stickerLogo.png')}
                    logoBackgroundColor="white"
                    size={120}
                    logoSize={30}
                    logoBorderRadius={0}
                    ecl={'M'}
                    backgroundColor={'white'}
                    color={constants?.color?.orange}
                  />
                </View>
                <TouchableOpacity
                  style={{
                    width: '100%',
                    backgroundColor: 'black',
                    borderRadius: 8,
                    marginVertical: 12,
                    marginHorizontal: 12,
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingVertical: 40,
                    paddingHorizontal: 40,
                    shadowColor: '#000',
                    shadowOffset: {width: 0, height: 1},
                    shadowOpacity: 0.6,
                    shadowRadius: 3,
                  }}>
                  <>
                    {!!buoy && (
                      <Image
                        source={buoy}
                        style={{width: 36, height: 36, resizeMode: 'contain'}}
                      />
                    )}
                    <View style={{display: 'flex', flexDirection: 'row'}}>
                      <BodyText
                        text={'Call us at'}
                        textStyle={{
                          color: 'white',
                          fontSize: 17,
                          fontFamily: 'JakartaBold',
                          marginLeft: 0,
                          marginRight: 0,
                        }}
                      />
                      <BodyText
                        text={'1-833-202-7626'}
                        textStyle={{
                          color: constants.color.orange,
                          fontSize: 17,
                          fontFamily: 'JakartaBold',
                          marginLeft: 5,
                          marginRight: 0,
                        }}
                      />
                    </View>
                    <BodyText
                      text={'for live support'}
                      textStyle={{
                        color: constants.color.white,
                        fontSize: 17,
                        fontFamily: 'JakartaBold',
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: 3,
                      }}
                    />
                    <BodyText
                      text={'Our friendly agents are standing by.'}
                      textStyle={{
                        color: constants.color.white,
                        fontSize: 12,
                        fontFamily: 'Jakarta',
                        marginLeft: 0,
                        marginRight: 0,
                        marginTop: 12,
                      }}
                    />
                    <ContinueButton
                      text={'Call support'}
                      height={50}
                      textStyle={{
                        fontFamily: 'JakartaBold',
                        textAlign: 'center',
                        flex: 1,
                      }}
                      containerStyle={{
                        marginTop: 24,
                        borderRadius: 6,
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        shadowColor: '#000',
                        shadowOffset: {width: 0, height: 1},
                        shadowOpacity: 0.6,
                        shadowRadius: 3,
                      }}
                      buttonStyle={{
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      handlePress={() => {
                        navigationRef.navigate(
                          'SupportModal',
                          {},
                          'Nav/DrawerMenu.tsx',
                        );
                      }}
                    />
                  </>
                </TouchableOpacity>
              </View>
            )}
          </View>
        </ScrollView>
      )}
      {view == 'lobby' && !isHybridHunt && (
        <ScrollView testID="lobbyScroll" contentContainerStyle={{}}>
          <View
            style={{
              width: '100%',
              maxWidth: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <View
              style={{
                width: '100%',
                height: 200,
                position: 'relative',
                backgroundColor: 'black',
                alignContent: 'center',
                alignItems: 'center',
              }}>
              {!!huntPhoto && (
                <ExpoImage
                  source={{uri: cloudFlareOptimizeImage(huntPhoto) || ''}}
                  style={{
                    height: 200,
                    width: '100%',
                  }}
                  resizeMode={'cover'}
                />
              )}
              <View
                testID="gameLobby"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: constants?.color?.gray3_50,
                  flexDirection: 'row',
                }}>
                {!!logoURL && (
                  <Image
                    style={{height: 67.5, width: 120}}
                    source={logoURL}
                    resizeMode={'contain'}
                  />
                )}
                <View>
                  <Text
                    style={{
                      color: constants?.color?.white,
                      fontFamily: constants?.font.gothic,
                      fontSize: 30,
                      textAlign: 'center',
                      marginHorizontal: 30,
                      marginBottom: 5,
                    }}>
                    {'GAME LOBBY'}
                  </Text>
                  <Text
                    style={{
                      color: constants?.color?.white,
                      fontFamily: constants?.font.gothic,
                      fontSize: 30,
                      textAlign: 'center',
                      marginHorizontal: 30,
                      marginBottom: 5,
                      maxWidth: width - 150,
                    }}>
                    {huntName?.toUpperCase?.()}
                  </Text>
                  {!eventId && (
                    <TouchableOpacity
                      onPress={() => {
                        navigationRef.navigate(
                          'ChangeHuntModal',
                          null,
                          'ScavengerHunt_v2/Lobby/GameLobby.tsx',
                        );
                      }}>
                      <Text
                        style={{
                          color: constants?.color?.white,
                          fontFamily: constants?.font.gothic,
                          fontSize: 18,
                          textDecorationLine: 'underline',
                          textAlign: 'center',
                          marginHorizontal: 30,
                          marginBottom: 5,
                        }}>
                        {'Change Hunt'}
                      </Text>
                    </TouchableOpacity>
                  )}
                </View>
              </View>
            </View>
            <BodyText
              textStyle={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                maxWidth: '100%',
                marginTop: Platform.OS == 'web' ? 0 : -20,
                backgroundColor: constants?.color.blue,
                textAlign: 'center',
                color: 'white',
                paddingTop: 7,
                paddingBottom: 5,
              }}>
              <Text style={{textAlign: 'center'}}>
                {'Start at: '}
                <TouchableWithoutFeedback
                  onPress={() => {
                    if (Platform.OS == 'android') {
                      Linking?.openURL(
                        'geo:0,0?q=' + formattedStartingLocation,
                      );
                    } else {
                      Linking?.openURL(
                        'http://maps.apple.com/?q=' + formattedStartingLocation,
                      );
                    }
                  }}>
                  <Text style={{textDecorationLine: 'underline'}}>
                    {formattedStartingLocation}
                  </Text>
                </TouchableWithoutFeedback>
              </Text>
            </BodyText>
            <TouchableOpacity
              style={{
                borderBottomColor: constants?.color?.gray3,
                paddingBottom: 3,
                minWidth: 250,
                marginTop: 0,
              }}
              onPress={() => showChangeName()}>
              <Text
                style={{
                  color: constants?.color?.gray3,
                  fontFamily: constants?.font.circular,
                  fontSize: 20,
                  fontWeight: '700',
                  marginLeft: 5,
                  marginTop: 10,
                  textAlign: 'center',
                }}>
                <Text testID="teamName">{`Your Team: ${teamName}`}</Text>
                <Text style={{marginLeft: 20}}>
                  <Icon
                    name={'pencil'}
                    size={16}
                    color={constants?.color?.gray3}
                  />
                </Text>
              </Text>
            </TouchableOpacity>
            <GameLobbyPlayers isModal={false} />
            <TouchableOpacity
              style={{
                borderBottomColor: constants?.color?.gray3,
                paddingBottom: 3,
                marginTop: 5,
              }}
              onPress={() => showChangeName()}>
              <Text
                style={{
                  color: constants?.color?.gray3,
                  fontFamily: constants?.font.circular,
                  fontSize: 20,
                  fontWeight: '700',
                  marginLeft: 5,
                  marginTop: 10,
                  textAlign: 'center',
                }}>
                {`Add Players - Different Devices`}
              </Text>
            </TouchableOpacity>
            <Text
              style={{
                color: constants?.color?.gray3,
                fontFamily: constants?.font.circular,
                marginHorizontal: 15,
                textAlign: 'center',
                fontSize: 14,
                marginTop: 5,
                marginBottom: 10,
              }}>
              Have friends scan or enter
              <TouchableOpacity
                testID="addPlayersToDevice"
                onPress={addPlayerToYourDevice}>
                <Text> </Text>
              </TouchableOpacity>
              your invite code
            </Text>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                maxWidth: '100%',
                backgroundColor: constants?.color.orange,
                padding: 20,
              }}>
              <TouchableOpacity
                onPress={() =>
                  qrCode && shareInviteCode(displayedCode || '', qrCode)
                }
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  position: 'relative',
                }}>
                <View>
                  <BodyText
                    textStyle={{
                      color: 'white',
                      fontSize: 30,
                      lineHeight: 30,
                    }}>
                    Invite Code
                  </BodyText>
                </View>
                <View>
                  <BodyText
                    textStyle={{
                      color: 'white',
                      fontSize: 30,
                      lineHeight: 30,
                    }}>
                    {`${displayedCode?.toUpperCase?.()}  `}
                    <Image
                      style={{
                        width: 24,
                        height: 24,
                        marginBottom: 12,
                        paddingBottom: 5,
                      }}
                      source={
                        Platform?.OS == 'android'
                          ? require('src/Images/android_share_icon_white.png')
                          : require('src/Images/ios_share_icon_white.png')
                      }
                    />
                  </BodyText>
                </View>
              </TouchableOpacity>
              <View style={{padding: 10, backgroundColor: 'white'}}>
                <QRCode
                  value={qrCode}
                  logo={require('src/Images/stickerLogo.png')}
                  logoBackgroundColor="white"
                  size={130}
                  logoSize={30}
                  logoBorderRadius={0}
                  ecl={'M'}
                  backgroundColor={'white'}
                  color={constants?.color?.orange}
                />
              </View>
            </View>
            <View
              style={{
                paddingBottom: 3,
                marginTop: 0,
              }}>
              <TouchableOpacity onPress={addPlayerToYourDevice}>
                <Text
                  style={{
                    color: constants?.color?.gray3,
                    fontFamily: constants?.font.circular,
                    fontSize: 20,
                    fontWeight: '700',
                    marginLeft: 5,
                    marginTop: 20,
                    textAlign: 'center',
                  }}>
                  {`Add Players - Share My Device`}
                </Text>
              </TouchableOpacity>
            </View>
            <Text
              style={{
                color: constants?.color?.gray3,
                fontFamily: constants?.font.circular,
                marginHorizontal: 15,
                textAlign: 'center',
                fontSize: 14,
                marginTop: 5,
                marginBottom: 0,
              }}>
              Challenges and activities will be sent to this device
            </Text>
            <ContinueButton
              text={'Add Players To My Device'}
              width={300}
              knockout
              handlePress={addPlayerToYourDevice}
              containerStyle={{marginTop: 20}}
            />

            {playTestState == false && (
              <TouchableOpacity
                style={{
                  paddingBottom: 3,
                  width: 200,
                  marginTop: 20,
                }}
                onPress={() => showChangeName()}>
                <Text
                  style={{
                    color: constants?.color?.gray3,
                    fontFamily: constants?.font.circular,
                    fontSize: 20,
                    fontWeight: '700',
                    marginLeft: 5,
                    marginTop: 0,
                    textAlign: 'center',
                  }}>
                  {`Settings`}
                </Text>
              </TouchableOpacity>
            )}

            {!!checkIfGroupNeedsTicketsToJoin() && (
              <TouchableOpacity
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginHorizontal: 30,
                  marginTop: 5,
                  paddingLeft: 10,
                  width: '100%',
                }}
                onPress={() => {
                  toggleType();
                }}>
                <CheckBox
                  value={checked}
                  onValueChange={() => toggleType()}
                  style={{width: 15, height: 15}}
                />
                <BodyText
                  text={'Allow new players to use my tickets'}
                  textStyle={{
                    marginBottom: 20,
                    marginTop: 15,
                    fontWeight: '700',
                    flex: 1,
                  }}
                />
              </TouchableOpacity>
            )}
            {playTestState == false && (
              <TouchableOpacity
                style={{
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginHorizontal: 30,
                  width: '100%',
                  paddingLeft: 10,
                  marginTop: 0,
                }}
                onPress={() => {
                  toggleDiscoverable();
                }}>
                <CheckBox
                  value={discoverable}
                  onValueChange={() => toggleDiscoverable()}
                  style={{width: 15, height: 15}}
                />
                <BodyText
                  text={`Easy join: group discoverable to nearby devices (Recommended)`}
                  textStyle={{
                    marginBottom: 20,
                    marginTop: 15,
                    fontWeight: '700',
                    flex: 1,
                  }}
                />
              </TouchableOpacity>
            )}
            {!!parsedDescription && (
              <Fragment>
                <View
                  style={{
                    paddingBottom: 3,
                    width: 300,
                    marginTop: 20,
                  }}>
                  <Text
                    testID="aboutThisHunt"
                    style={{
                      color: constants?.color?.gray3,
                      fontFamily: constants?.font.circular,
                      fontSize: 20,
                      fontWeight: '700',
                      marginLeft: 5,
                      marginTop: 0,
                      textAlign: 'center',
                    }}>
                    {`About This ${activity_description}`}
                  </Text>
                </View>
                <View
                  style={{
                    marginHorizontal: 20,
                    marginVertical: 10,
                  }}>
                  <HtmlBlock
                    htmlStyle={{
                      textAlign: 'left',
                      fontFamily: 'CircularStd-Book',
                      fontSize: 16,
                      lineHeight: 20,
                      color: '#505759',
                    }}
                    html={parsedDescription || ''}
                  />
                </View>
                {huntType !== 'inHome' && (
                  <GetDirectionsCard
                    starting_location={formattedStartingLocation}
                    lat={lat || 0}
                    long={long || 0}
                    width={width - 40}
                  />
                )}
              </Fragment>
            )}
            <View
              style={{
                paddingBottom: 3,
                width: 300,
                marginTop: 20,
              }}>
              <Text
                style={{
                  color: constants?.color?.gray3,
                  fontFamily: constants?.font.circular,
                  fontSize: 20,
                  fontWeight: '700',
                  marginLeft: 5,
                  marginTop: 0,
                  textAlign: 'center',
                }}>
                {`Need Help?`}
              </Text>
            </View>
            <ContinueButton
              text={'Contact Support'}
              width={300}
              knockout
              handlePress={() =>
                navigationRef.navigate(
                  'SupportModal',
                  {},
                  'ScavengerHunt_v2/Lobby/GameLobby.tsx',
                )
              }
              containerStyle={{marginTop: 20}}
            />
            <View style={{height: 200, width: 200}} />
          </View>
        </ScrollView>
      )}

      <ModalOmniBar
        left={{
          icon: 'arrow-left-circle',
          handlePress: () =>
            navigationRef.navigate(
              'LandingPageMain',
              null,
              'ScavengerHunt_v2/Lobby/GameLobby.tsx',
            ),
          text: 'Exit Lobby',
        }}
        omniButton={{
          icon: require('src/Images/logo.png'),
          color: constants?.color?.orange,
          handlePress: () => {
            try {
              const eventStartTimeTZ = 'America/Denver';

              // convert eventStartTime to a moment object in Mountain Time
              const eventStartMoment = moment.tz(
                eventStartTime,
                eventStartTimeTZ,
              );

              // get the current time in Mountain Time
              const currentTime = moment().tz(eventStartTimeTZ);

              if (eventStartTime && currentTime.isBefore(eventStartMoment)) {
                // const formattedStartDate =
                //   eventStartMoment.format('MM/DD/YYYY');

                return Alert.alert(
                  `You cannot start the hunt yet. In the meantime, be sure to invite people to join your team.`,
                );
              }

              if (createdBy === userId || showBegin || huntStarted) {
                handleStartPress();
              } else {
                Alert.alert(
                  'You Did Not Create The Hunt',
                  `Only the person who made the hunt can start the hunt. Please have the team leader start the hunt or ask them to leave the hunt. `,
                );
              }
            } catch (error: unknown) {
              handleStartPress();
            }
          },
          text: huntStarted
            ? `Continue ${isHybridHunt ? 'Event' : 'Hunt'}`
            : `Start ${isHybridHunt ? 'Event' : 'Hunt'}`,
        }}
        right={{
          icon: 'share',
          handlePress: () =>
            qrCode && shareInviteCode(displayedCode || '', qrCode),
          text: 'Share Invite Code',
        }}
        containerStyle={{
          position: 'absolute',
          left: 0,
          right: 0,
          bottom: 0,
        }}
      />
      <AddToDeviceModal
        isVisible={showAddPlayers}
        closeModal={() => setShowAddPlayers(false)}
        addChildUser={(firstName, parentUserId, parentUserEmail, groupId) => {
          addChildUser(firstName, parentUserId, parentUserEmail, groupId);
        }}
      />
    </SafeAreaView>
  );
};

export default GameLobby;
