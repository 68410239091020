import {TThemesState} from 'src/types/TTheme';

export const defaultThemes: TThemesState = {
  themes: {
    Standard: {
      theme: 'Standard',
      text: 'Standard',
      short_description:
        'Designed for friends and families, our standard theme is a great way for everyone to have fun and explore the city.',
      description:
        'Designed for friends and families, our standard theme is a great way for everyone to have fun and explore the city.',
      coin_price: 1000,
      dollar_price: 11.99,
      show_in_backpack: true,
      published: true,
      icon_source:
        'https://www.letsroam.com/assets/images/app/themes/Standard.png',
      default_players: 2,
      characters: ['Explorer', 'Brainiac', 'Photographer', 'Youngster'],
    },
    'Date Night': {
      theme: 'Date Night',
      text: 'Date Night',
      short_description:
        'For couples on their first date or their fiftieth, our date-themed scavenger hunt promotes connection, communication and friendly competition.',
      description:
        'For couples on their first date or their fiftieth, our date-themed scavenger hunt promotes connection, communication and friendly competition.',
      coin_price: 1500,
      dollar_price: 19.99,
      show_in_backpack: true,
      published: true,
      icon_source:
        'https://www.letsroam.com/assets/images/app/themes/DateNight.png',
      default_players: 2,
      characters: ['Heartthrob', 'The Keeper', 'Romantic', 'Heartbreaker'],
    },
    Bachelorette: {
      theme: 'Bachelorette',
      text: 'Bachelorette',
      short_description:
        'The ultimate bachelorette party for the bride-to-be and her besties.',
      description:
        'The ultimate bachelorette party for the bride-to-be and her besties.',
      coin_price: 2000,
      dollar_price: 11.99,
      show_in_backpack: true,
      published: true,
      icon_source:
        'https://www.letsroam.com/assets/images/app/themes/Bachelorette2.png',
      default_players: 2,
      characters: [
        'Blushing Bride',
        'Dancing Queen',
        'Bouquet Catcher',
        'Fun-time Photographer',
      ],
    },
    Birthday: {
      theme: 'Birthday',
      text: 'Birthday',
      short_description:
        'Gamify your big day with an activity that makes the birthday star the center of attention. Perfect for kids and adults alike.',
      description:
        'Gamify your big day with an activity that makes the birthday star the center of attention. Perfect for kids and adults alike.',
      coin_price: 1600,
      dollar_price: 11.99,
      show_in_backpack: true,
      published: true,
      icon_source:
        'https://www.letsroam.com/assets/images/app/themes/Birthday.png',
      default_players: 2,
      characters: [
        'Birthday Star',
        'Party Animal',
        'Party DJ',
        'Shutterbug',
        'Cake Thrower',
      ],
    },
    Bachelor: {
      theme: 'Bachelor',
      text: 'Bachelor',
      short_description:
        'Bro code meets Da Vinci code in this thrilling hunt made for the man of the hour.',
      description:
        'Bro code meets Da Vinci code in this thrilling hunt made for the man of the hour.',
      coin_price: 2000,
      dollar_price: 11.99,
      show_in_backpack: true,
      published: true,
      icon_source:
        'https://www.letsroam.com/assets/images/app/themes/Bachelor.png',
      default_players: 2,
      characters: ['Groom Kong', 'Ice Breaker', 'The Prophet', 'The Bookie'],
    },
    'Team Building': {
      theme: 'Team Building',
      text: 'Team Building',
      short_description: ' ',
      description: ' ',
      coin_price: 2000,
      dollar_price: 11.99,
      show_in_backpack: false,
      published: false,
      icon_source:
        'https://www.letsroam.com/assets/images/app/characters/Innovator.png',
      default_players: 2,
      characters: ['Team Player', 'Strategist', 'Visionary', 'Innovator'],
    },
    'In-Home': {
      theme: 'In-Home',
      text: 'In-Home',
      short_description:
        "Let's Roam in-home hunts are the perfect way to hang out indoors. Unlike other hunts, you only need 1 ticket for your whole group.",
      description:
        "Let's Roam indoor hunts are the perfect way to hang out indoors. Unlike other hunts, you only need 1 ticket for your whole group.",
      coin_price: 2000,
      dollar_price: 11.99,
      show_in_backpack: true,
      published: true,
      icon_source:
        'https://www.letsroam.com/assets/images/app/themes/Standard.png',
      default_players: 1,
      characters: ['In-Home Explorer', 'In-Home Photographer'],
    },
  },
  characters: {
    Explorer: {
      theme: 'Standard',
      character: 'Explorer',
      description:
        'The explorer loves to see the world. In this role, you\u2019ll put your adventure-seeking skills to use by completing a thrilling mix of trivia and photo challenges. Go forth and discover! ',
      question_type: 'mixed',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Explorer.png',
    },
    Brainiac: {
      theme: 'Standard',
      character: 'Brainiac',
      description:
        'The brainiac is super smart. Sound like you? As the intellect of the group, you\u2019ll rock some tricky trivia questions. You got this!',
      question_type: 'trivia',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Brainiac.png',
    },
    Photographer: {
      theme: 'Standard',
      character: 'Photographer',
      description:
        'You\u2019re a social butterfly and snapping pics is your superpower.   Grab your camera and conquer unforgettable photo challenges. ',
      question_type: 'photos',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Photographer.png',
    },
    Youngster: {
      theme: 'Standard',
      character: 'Youngster',
      description:
        'You may be the youngest member of the team, but that doesn\u2019t mean you have to sit on the sidelines. Choose the youngster role and get in on the action! Perfect for kids ages 5 and up.',
      question_type: 'kid',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Youngster.png',
    },
    Sightseer: {
      theme: 'Standard',
      character: 'Sightseer',
      description:
        'Sit back, relax and enjoy the hunt! You won\u2019t receive any challenges but you\u2019ll get to join your friends on this epic adventure.',
      question_type: 'none',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Sightseer.png',
    },
    Heartthrob: {
      theme: 'Date Night',
      character: 'Heartthrob',
      description:
        'With all that talent and charm, it\u2019s no wonder everyone wants to date you! You\u2019re great at trivia and you have an eye for photography. Go get \u2019em tiger!',
      question_type: 'mixed',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Heartthrob.png',
    },
    'The Keeper': {
      theme: 'Date Night',
      character: 'The Keeper',
      description:
        'You\u2019re the better half who knows it all. Show off your expertise by solving brain-teasing trivia questions.',
      question_type: 'trivia',
      source:
        'https://www.letsroam.com/assets/images/app/characters/TheKeeper.png',
    },
    Romantic: {
      theme: 'Date Night',
      character: 'Romantic',
      description:
        'You love love! You find the best lighting to take flawless photos, which is why you\u2019ll have more photo challenges!',
      question_type: 'photos',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Romantic.png',
    },
    Heartbreaker: {
      theme: 'Date Night',
      character: 'Heartbreaker',
      description:
        'Let your little Cupid join in on the fun! Kid-friendly challenges and questions keep your child entertained and engaged while exploring. Perfect for ages 5 and up.',
      question_type: 'kid',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Heartbreaker.png',
    },
    Planner: {
      theme: 'Date Night',
      character: 'Planner',
      description:
        'Sit back, relax and enjoy the hunt! You won\u2019t receive any challenges but you\u2019ll get to join your date on this epic adventure.',
      question_type: 'none',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Planner.png',
    },
    'Blushing Bride': {
      theme: 'Bachelorette',
      character: 'Blushing Bride',
      description:
        "We're here to celebrate you! Get ready for fun photo challenges and trivia!",
      question_type: 'special',
      source: 'https://www.letsroam.com/assets/images/app/characters/bride.png',
    },
    'Dancing Queen': {
      theme: 'Bachelorette',
      character: 'Dancing Queen',
      description:
        'You know how to get a party started! Take on trivia and photo challenges.',
      question_type: 'mixed',
      source:
        'https://www.letsroam.com/assets/images/app/characters/HotMess.png',
    },
    'Bouquet Catcher': {
      theme: 'Bachelorette',
      character: 'Bouquet Catcher',
      description:
        "Catch only trivia with this role to keep things bloomin'.  ",
      question_type: 'trivia',
      source:
        'https://www.letsroam.com/assets/images/app/characters/KnowItAll.png',
    },
    'Fun-time Photographer': {
      theme: 'Bachelorette',
      character: 'Fun-time Photographer',
      description:
        'You know that details matter and that great photos are essential. Capture all the amazing moments throughout the scavenger hunt!',
      question_type: 'photos',
      source:
        'https://www.letsroam.com/assets/images/app/characters/ThunderThief.png',
    },
    'Toast Maker': {
      theme: 'Bachelorette',
      character: 'Toast Maker',
      description:
        'You won\u2019t receive any challenges, but you\u2019ll get to join the I-do crew on this epic adventure. Cheers!',
      question_type: 'none',
      source:
        'https://www.letsroam.com/assets/images/app/characters/ToastMaker.png',
    },
    'Birthday Star': {
      theme: 'Birthday',
      character: 'Birthday Star',
      description:
        'It\u2019s your special day! Photo challenges? Trivia? You got them covered. Because hey, you\u2019re a star!',
      question_type: 'special',
      source:
        'https://www.letsroam.com/assets/images/app/characters/BirthdayStar.png',
    },
    'Party Animal': {
      theme: 'Birthday',
      character: 'Party Animal',
      description:
        'It\u2019s always a party when you\u2019re around! You\u2019re the most well-rounded in the group. So use your superpowers to complete a mix of photo and trivia challenges.',
      question_type: 'mixed',
      source:
        'https://www.letsroam.com/assets/images/app/characters/PartyAnimal.png',
    },
    'Party DJ': {
      theme: 'Birthday',
      character: 'Party DJ',
      description:
        'As the pop culture guru, you know all the hottest music and how to get everyone on their feet! Since you know so much, you\u2019ll receive more trivia challenges.',
      question_type: 'trivia',
      source:
        'https://www.letsroam.com/assets/images/app/characters/PartyDJ.png',
    },
    Shutterbug: {
      theme: 'Birthday',
      character: 'Shutterbug',
      description:
        'You know what\u2019s lit and what\u2019s not! So it\u2019s only fitting that you\u2019ll receive more photo challenges\u2014and show everyone how to use a filter for the most Insta-perfect pictures.',
      question_type: 'photos',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Shutterbug.png',
    },
    'Cake Thrower': {
      theme: 'Birthday',
      character: 'Cake Thrower',
      description:
        'Have your cake and eat it too with fun, kid-friendly challenges and questions. Perfect for kids ages 5 and up!',
      question_type: 'kid',
      source:
        'https://www.letsroam.com/assets/images/app/characters/CakeThrower.png',
    },
    'Groom Kong': {
      theme: 'Bachelor',
      character: 'Groom Kong',
      description:
        'This night is all about you\u2014the bachelor. Live it up with all your friends... because they\u2019re buying!',
      question_type: 'special',
      source:
        'https://www.letsroam.com/assets/images/app/characters/GroomKong.png',
    },
    'Ice Breaker': {
      theme: 'Bachelor',
      character: 'Ice Breaker',
      description:
        'You\u2019re everyone\u2019s wingman and you always have a story that gets a good laugh! You\u2019re friendly and charismatic, which means you\u2019re the right person to complete photo and trivia challenges.',
      question_type: 'mixed',
      source:
        'https://www.letsroam.com/assets/images/app/characters/IceBreaker.png',
    },
    'The Prophet': {
      theme: 'Bachelor',
      character: 'The Prophet',
      description:
        'You\u2019ve planned the night and predicted every possible outcome. You want everyone to have a good time and make bad desicions. Your insights are perfect for the most brain-teasing trivia challenges.',
      question_type: 'trivia',
      source:
        'https://www.letsroam.com/assets/images/app/characters/TheProphet.png',
    },
    'The Bookie': {
      theme: 'Bachelor',
      character: 'The Bookie',
      description:
        "You know your friends better than anyone and nothing they do surprises you. With your camera you\u2019ll secure evidence of today's events.",
      question_type: 'photos',
      source:
        'https://www.letsroam.com/assets/images/app/characters/TheBookie.png',
    },
    'The DD': {
      theme: 'Bachelor',
      character: 'The DD',
      description:
        'Sit back, relax and enjoy the hunt. You won\u2019t receive any challenges, but you\u2019ll get to join your friends on this epic adventure.',
      question_type: 'none',
      source: 'https://www.letsroam.com/assets/images/app/characters/TheDD.png',
    },
    sightseer: {
      theme: 'Standard',
      character: 'sightseer',
      description:
        'Sit back, relax and enjoy the hunt! You won\u2019t receive any challenges but you\u2019ll get to join your friends on this epic adventure.',
      question_type: 'none',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Sightseer.png',
    },
    brainiac: {
      theme: 'Standard',
      character: 'brainiac',
      description:
        'The brainiac is super smart. Sound like you? As the intellect of the group, you\u2019ll rock some tricky trivia questions. You got this!',
      question_type: 'trivia',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Brainiac.png',
    },
    photographer: {
      theme: 'Standard',
      character: 'photographer',
      description:
        'You\u2019re a social butterfly and snapping pics is your superpower.   Grab your camera and conquer unforgettable photo challenges. ',
      question_type: 'photos',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Photographer.png',
    },
    explorer: {
      theme: 'Standard',
      character: 'explorer',
      description:
        'The explorer loves to see the world. In this role, you\u2019ll put your adventure-seeking skills to use by completing a thrilling mix of trivia and photo challenges. Go forth and discover! ',
      question_type: 'mixed',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Explorer.png',
    },
    youngster: {
      theme: 'Standard',
      character: 'youngster',
      description:
        'You may be the youngest member of the team, but that doesn\u2019t mean you have to sit on the sidelines. Choose the youngster role and get in on the action! Perfect for kids ages 5 and up.',
      question_type: 'special',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Youngster.png',
    },
    'Team Player': {
      theme: 'Team Building',
      character: 'Team Player',
      description:
        'You are the balanced one, the mediator, the one willing to take on any task regardless of your department. You\u2019ll take on a healthy mix of trivia and photo challenges. We know your team can count on you.',
      question_type: 'mixed',
      source:
        'https://www.letsroam.com/assets/images/app/characters/TeamPlayer.png',
    },
    Strategist: {
      theme: 'Team Building',
      character: 'Strategist',
      description:
        'You are the sketcher, the planner, and you make one heck of a flow chart. You are always three steps ahead, and you\u2019ve got the data to prove it. You are the perfect candidate to tackle the toughest trivia challenges.',
      question_type: 'trivia',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Strategist.png',
    },
    Visionary: {
      theme: 'Team Building',
      character: 'Visionary',
      description:
        'You can see things that others can\u2019t, your guiding sight will help lead your team to victory by completing more photo challenges and documenting all those special memories you make along the way.',
      question_type: 'photos',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Visionary.png',
    },
    Innovator: {
      theme: 'Team Building',
      character: 'Innovator',
      description:
        'You do things a little differently, and you\u2019re here to show the rest of us not how it\u2019s done now, but how it should be done. That\u2019s why you\u2019ll get our special blend of photo challenges with just a touch of trivia sprinkled in.',
      question_type: 'mixed',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Innovator.png',
    },
    'In-Home Explorer': {
      theme: 'In-Home',
      character: 'In-Home Explorer',
      description:
        'The explorer loves to see the world. In this role, you\u2019ll put your adventure-seeking skills to use by completing a thrilling mix of trivia and photo challenges. Go forth and discover! ',
      question_type: 'mixed',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Explorer.png',
    },
    'In-Home Photographer': {
      theme: 'In-Home',
      character: 'In-Home Photographer',
      description:
        'You\u2019re a social butterfly and snapping pics is your superpower.   Grab your camera and conquer unforgettable photo challenges. ',
      question_type: 'photos',
      source:
        'https://www.letsroam.com/assets/images/app/characters/Photographer.png',
    },
  },
};
