import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import {Camera, CameraView, CameraType, FlashMode} from 'expo-camera';
import React, {useEffect, useRef, createRef, useState} from 'react';
import {Alert, Dimensions, Platform, View} from 'react-native';
import useParams from 'src/Hooks/useParams';
import CameraControlRow from 'src/Modules/CommonGame/Camera/CameraControlRow';
import PermissionsWarning from 'src/Modules/CommonGame/Camera/PermissionsWarning';
const {width} = Dimensions.get('window');

import {v4 as uuidv4} from 'uuid';
import {
  logAttributesForCrashlytics,
  updateDataAtPath,
  uploadMedia,
} from 'src/Utils/fireUtils';
import {BodyText} from 'src/Modules/CommonView/Text';

import {navigationRef} from 'src/Nav/navigationHelpers';
import {launchImageLibrary} from 'react-native-image-picker';
import VideoTimeLeft from 'src/Modules/CommonGame/Camera/VideoTimeLeft';

import {createShareLink} from 'src/Utils/shareHelper';

import * as Device from 'expo-device';

import {useUserId} from 'src/Hooks/reduxHooks';
import ExpoImage from 'src/Utils/ExpoImage';
import DelayedLoad from 'src/Nav/DelayedLoad';
import {
  setHasCameraPermissions,
  setPhotoTaken,
} from 'src/Redux/reducers/current_screen.reducer';
import {dispatchAction} from 'src/Utils/helpers';
import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import Video from 'src/Modules/Native/Video';
import {ResizeMode} from 'expo-av';
import FocusedWrapper from 'src/Nav/FocusedWrapper';
import {onScoreResponse} from 'src/types/TNavigationRouteParams';
import {saveLocalMediaToCameraRoll} from 'src/Utils/cameraHelpers';
import {useChallenge} from 'src/Hooks/gameHooks';

const PhotoVideoCameraWrapper = () => {
  return (
    <DelayedLoad delay={50}>
      <FocusedWrapper>
        <PhotoVideoCamera></PhotoVideoCamera>
      </FocusedWrapper>
    </DelayedLoad>
  );
};

export default PhotoVideoCameraWrapper;

function PhotoVideoCamera() {
  const {
    onCapture,
    onScore,
    onFailScore,
    onPhotoFirstUploadedToFirebase,
    text = 'hello',
    feedbackUnits = 'Points',
    feedbackPoints = false,
    aiScoring = false,
    isRetake = false,
    hideShare = false,
    shareUUID: initialShareUUID = 0,
    photoUrl: initialPhotoUrl = null,
    showPhotoUpload,
  } = useParams<'PhotoVideoCamera'>();

  let {isVideo = false} = useParams<'PhotoVideoCamera'>();
  if (__DEV__ && !Device?.isDevice) {
    isVideo = false;
  }

  const userId = useUserId();
  const groupId =
    useTypedSelector((state) => state?.group?.info?.groupId) ||
    `local-${userId}`;

  const hasCameraPermission = useTypedSelector(
    (state) => state.current_screen?.hasCameraPermission,
  );

  const deviceUUID = useTypedSelector((state) => state.app_info?.deviceUUID);

  useEffect(() => {
    logAttributesForCrashlytics?.(
      'hasCameraPermission',
      `${hasCameraPermission || ''}`,
    );
  }, [hasCameraPermission]);

  const cameraRef = createRef<CameraView>();

  console.log({cameraRef});

  const [facing, setFacing] = useState<CameraType>('back');
  const [tempPhoto, setTempPhoto] = useState<string | null>(null);
  const [photo, setPhoto] = useState<string | null>(null);
  const [tempVideo, setTempVideo] = useState<string | null>(null);
  const [video, setVideo] = useState<string | null>(null);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const isRecordingRef = useRef<boolean>(false);
  const isCancelledRequest = useRef<boolean>(false);
  const [shareUUID, setShareUUID] = useState<string | null>(null);
  const [flash, setFlash] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [isFromCameraRole, setIsFromCameraRole] = useState<boolean>(false);
  // const [allowMoreRetakes, setAllowMoreRetakes] = useState<boolean>(isRetake);

  const [isCameraReady, setIsCameraReady] = useState<boolean>(false);

  const onCameraReady = () => {
    setIsCameraReady(true);
  };

  const challenge = useChallenge?.();

  // const photoCompletionPoints = challenge?.points;
  // const photoBonusData = challenge?.bonusScoreData;
  const {points: photoCompletionPoints, bonusScoreData: photoBonusData} =
    challenge || {};

  console.log('photoBonusData', photoBonusData);

  console.log_on_production('PhotoVideoCamera render', {
    onCapture,
    onScore,
    onFailScore,
    text,
    feedbackUnits,
    feedbackPoints,
    hideShare,
    isVideo,
    shareUUID,
    initialPhotoUrl,
    showPhotoUpload,
    hasCameraPermission,
  });

  useEffect(() => {
    if (initialPhotoUrl) {
      isVideo ? setVideo(initialPhotoUrl) : setPhoto(initialPhotoUrl);
    }
  }, [initialPhotoUrl]);

  const requestPermissions = async () => {
    const {status} = await Camera?.requestCameraPermissionsAsync?.();
    Camera.requestMicrophonePermissionsAsync();
    const newStatus = status === 'granted';
    console.log_on_production({status});
    updateDataAtPath(`devices/${deviceUUID || 'error'}/deviceData`, {
      cameraPermissions: status,
    });
    if (newStatus != hasCameraPermission) {
      console.log_on_production({newStatus, hasCameraPermission});
      setTimeout(() => {
        dispatchAction(setHasCameraPermissions(newStatus));
      }, 50);
    }
  };

  // asking all permissions
  useEffect(() => {
    requestPermissions();
  }, []);

  const permissionsDenied = !hasCameraPermission;

  // resetting the camera function
  const resetCamera = () => {
    setTempPhoto(null);
    setPhoto(null);
    setVideo(null);
    setTempVideo(null);
    setUploading(false);
    isCancelledRequest.current = true;
    setIsFromCameraRole(false);
  };

  // creating the share link
  // as an effect when the video is uploaded
  useEffect(() => {
    if (initialShareUUID) {
      return setShareUUID(initialShareUUID);
    }
    const shareMedia = isVideo ? video : photo;
    if (shareMedia) {
      // creating share link
      const newShareUUID = uuidv4().substring(0, 8);
      setShareUUID(newShareUUID);
      createShareLink(newShareUUID, shareMedia, text, null);
    } else {
      if (shareUUID && !initialShareUUID) {
        setShareUUID(null);
      }
    }
  }, [photo, video]);

  const saveMedia = async (uri: string) => {
    if (isVideo) {
      let videoStep = 0;
      try {
        videoStep = 1;
        console.log_on_production('saving video', uri);

        // saving the video
        setVideo(video);
        console.log_on_production('saveMedia', {video: uri});

        videoStep = 2;

        // uploading the video
        const imageId = uuidv4();
        const imagePath = `videos/ScavengerHunt/${groupId}/${imageId}`;
        videoStep = 2.5;
        const uploadedVideo = await uploadMedia(imagePath, uri, true, false);
        videoStep = 3;
        if (!!isCancelledRequest?.current)
          return console.log_on_production('request was cancelled');
        videoStep = 4;
        console.log_on_production('setting video', {uploadedVideo});
        setVideo(uploadedVideo || null);
        dispatchAction(setPhotoTaken(0));
        console.log_on_production({uploadedVideo});
        videoStep = 5;
        return uploadedVideo;
      } catch (e) {
        Alert.alert(
          'Video Could Not Be Saved',
          'Please try again. Video Error Code: ' + videoStep + `\b uri: ${uri}`,
        );
        return;
      }
    } else {
      let photoStep = 0;
      try {
        console.log_on_production('saving photo', uri);

        // uploading the media
        const imageId = uuidv4();
        photoStep = 1;
        const imagePath = `images/ScavengerHunt/${groupId}/${imageId}`;
        photoStep = 2;
        const uploadedPhoto = await uploadMedia(imagePath, uri, false, true);

        if (!uploadedPhoto) {
          return console.error('no uploaded photo');
        }

        photoStep = 3;
        if (!!isCancelledRequest?.current)
          return console.log_on_production('request was cancelled');

        setPhoto(uploadedPhoto);

        if (typeof onPhotoFirstUploadedToFirebase == 'function') {
          onPhotoFirstUploadedToFirebase(uploadedPhoto);
        }

        photoStep = 4;
        console.log_on_production({uploadedPhoto});

        photoStep = 5;

        return uploadedPhoto;
      } catch (e) {
        let errorMessage = 'An unknown error occurred';

        if (
          typeof e === 'object' &&
          e !== null &&
          'message' in e &&
          typeof e.message == 'string'
        ) {
          errorMessage = e.message;
        }

        Alert.alert(
          'Photo Could Not Be Saved',
          'Please try again. Photo saveMedia Error Code: ' +
            photoStep +
            `\b uri: ${uri} + \b ${errorMessage}`,
        );
        return;
      }
    }
  };

  const handleCapture = async () => {
    isCancelledRequest.current = false;
    if (cameraRef.current) {
      console.log_on_production('ready to take picture', {
        isDevice: Device.isDevice,
      });
      // local simulator does not work on save
      if (Platform.OS == 'web' || !Device.isDevice) {
        console.log_on_production('using mocked photo');
        const uri = isVideo
          ? 'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/images%2FScavengerHunt%2F13b59db%2Fd1e5bf64-106d-4d38-8738-67da24cf3b0a.mp4?alt=media&token=2638b5a1-c6c1-4860-ae38-3537954a850e'
          : 'https://firebasestorage.googleapis.com/v0/b/barhuntv2.appspot.com/o/Group_illus.jpg?alt=media&token=d275d2ff-3d39-4d57-9497-adb10b5616f2';
        setTempPhoto(uri);
        saveMedia(uri);
        // setAllowMoreRetakes(false);
        // checking if camera not ready
      } else if (!isCameraReady) {
        console.log('camera not ready yet');
        return setTimeout(() => {
          setIsCameraReady(true);
        }, 1000);
      } else if (isVideo) {
        // taking the photo
        setIsRecording(true);
        isRecordingRef.current = true;

        const video = (
          await cameraRef?.current?.recordAsync({
            maxDuration: 15,
          })
        )?.uri;
        dispatchAction(setPhotoTaken(1));
        isRecordingRef.current = false;

        setTempVideo(video || null);
        setIsRecording(false);

        if (video) {
          saveMedia(video);
        }
      } else {
        // taking the photo
        console.log_on_production('taking photo');
        dispatchAction(setPhotoTaken(1));
        cameraRef?.current
          ?.takePictureAsync({quality: 0.2})
          .then((photo) => {
            if (photo) {
              dispatchAction(setPhotoTaken(0));
              const {uri} = photo;
              console.log_on_production('photo taken', {photo});

              setTempPhoto(uri);

              saveMedia(uri);
              // setAllowMoreRetakes(false);
            }
          })
          .catch((e) => {
            if (e?.code != 'E_ANOTHER_CAPTURE') {
              Alert.alert(
                'Error Taking Photo',
                __DEV__
                  ? JSON.stringify(e)
                  : 'Your camera is still starting up. Try taking the photo again in a few seconds.',
              );
            }
          });
      }
    } else {
      console.log_on_production('no camera!!!');
    }
  };

  const autoSavePhotos = useTypedSelector(
    (state) => state.current_screen?.autoSavePhotos,
  );

  const handleSubmitChallenge = async () => {
    let step = 0;
    try {
      let newMedia = isVideo ? video : photo;
      let newShareUUID = shareUUID;

      step = 1;
      // saving the photo if not already saved to firebase
      setUploading(true);
      if (!newMedia) {
        console.log_on_production('No uploaded photo, reuploading');
        step = 1.5;
        const itemToSave = isVideo ? tempVideo : tempPhoto;
        if (!itemToSave) {
          navigationRef.simpleAlert('No photo to save', 'Please try again');
          return;
        }
        newMedia = (await saveMedia(itemToSave)) || null;
        step = 1.6;
        console.log_on_production('new media saved', {newMedia});
      }

      if (autoSavePhotos && newMedia && photo) {
        const localMedia = tempVideo || tempPhoto;
        if (localMedia && !localMedia?.includes?.('https://')) {
          console.log('auto-saving photo', {localMedia});
          await saveLocalMediaToCameraRoll(localMedia);
        }
      }

      step = 2;
      if (!newMedia) {
        resetCamera();
        return Alert.alert(
          'Error',
          'Photo could not be saved, check your internet connection.',
        );
      }
      step = 3;
      if (!newShareUUID) {
        newShareUUID = uuidv4().substring(0, 8);
        setShareUUID(newShareUUID);
        createShareLink(newShareUUID, newMedia, text, null);
      }
      step = 4;
      const shareParams = {
        shareUUID: newShareUUID,
        description: text,
        photoUrl: newMedia,
        userId,
      };
      let scoreResponse: onScoreResponse | false = false;
      let lr_app_challenge_completion_id: string | null = null;
      step = 5;
      // handling photo scoring if defined
      if (newMedia && onScore && onFailScore) {
        scoreResponse =
          (await onScore({
            downloadURL: newMedia,
            thumbnail: isVideo ? photo : null,
            imagePath: newMedia,
            shareUUID: newShareUUID,
          })) || false;
        step = 6;
        lr_app_challenge_completion_id = scoreResponse
          ? scoreResponse?.lr_app_challenge_completion_id || null
          : null;
        // return Alert.alert('data', JSON.stringify(scoreResponse));
        if (!!scoreResponse && !scoreResponse?.photo_grade?.is_correct) {
          // navigationRef?.goBack('Modules/CommonGame/Camera/PhotoVideoCamera.tsx');
          step = 6.5;
          resetCamera();
          step = 6.9;
          console.log_on_production({step, onFailScore, scoreResponse});
          return onFailScore(scoreResponse);
        }
        step = 7;
      }

      // the photo is now downloading
      // hiding the modal
      navigationRef?.goBack('Modules/CommonGame/Camera/PhotoVideoCamera.tsx');
      step = 8;
      !hideShare &&
        shareParams &&
        navigationRef.navigate(
          aiScoring ? 'PhotoScoreAndShareModal' : 'ShareModal',
          {
            shareObject: shareParams,
            shareOrigin: 'Photo',
            scoreResponse: scoreResponse || undefined,
            showCorrectPoints: feedbackPoints ? String(feedbackPoints) : null,
            showCorrectUnits: feedbackUnits,
            photoBonusData: photoBonusData,
            photoCompletionPoints: `${photoCompletionPoints}`,
            challenge: challenge ?? undefined,
          },
          'PhotoVideoCamera share',
        );
      console.log_on_production('about to fire onCapture');
      step = 9;
      onCapture &&
        onCapture({
          downloadURL: newMedia,
          shareUUID: String(newShareUUID || null),
          lr_app_challenge_completion_id,
          isFromCameraRole: isFromCameraRole || false,
        });
      step = 10;
    } catch (handleSubmitChallengeError) {
      console.log_on_production({handleSubmitChallengeError});
      Alert.alert(
        'Photo Could Not Be Saved',
        'Please try again. Error Code: ' + step,
      );
      // constants?.devAlert(error, 0, 1);
      resetCamera();
    }
  };

  const handleCameraRoll = () => {
    launchImageLibrary(
      {selectionLimit: 1, mediaType: 'photo', includeExtra: true},
      (response) => {
        console.log_on_production('Response = ', response);
        if (response.didCancel) {
          console.log_on_production('User cancelled image picker');
        } else {
          const newPhoto = response?.assets?.[0]?.uri;
          if (!newPhoto) {
            return console.log('no photo selected');
          }
          setIsFromCameraRole(true);
          setTempPhoto(newPhoto);
          uploadMedia(newPhoto, newPhoto, false, false);
        }
      },
    );
  };

  const handleShare = () => {
    // navigationRef.devAlert(shareUUID);
    const shareParams = {
      shareUUID,
      description: text,
      photoUrl: initialPhotoUrl || photo || video,
      userId,
    };
    navigationRef.navigate(
      'PhotoScoreAndShareModal',
      {
        shareObject: shareParams,
        shareOrigin: 'Photo',
      },
      'PhotoVideoCamera share',
    );
  };

  const displayPhoto = photo || tempPhoto || video;
  // const displayPhoto = true;
  // const isDone = false;

  const isDone = !!(video || displayPhoto || initialPhotoUrl);

  // const isDone = true;
  // if there is a tempPhoto
  // isDone needs to be true

  // photo, tempPhoto, initialPhotoUrl

  // const displayPhoto = null;

  console.log_on_production('PhotoVideoCamera render', {
    hasCameraPermission,
    facing,
    photo,
    video,
    permissionsDenied,
    isDone,
  });

  return (
    <View
      key={String(permissionsDenied) + String(isDone)}
      style={{flex: 1, position: 'relative', backgroundColor: 'black'}}>
      {!permissionsDenied && !isDone && (
        <CameraView
          ref={cameraRef}
          key={String(permissionsDenied) + String(isDone)}
          style={{flex: 1}}
          facing={facing}
          ratio={'16:9'}
          videoQuality={'480p'}
          onCameraReady={onCameraReady}
          flash={flash ? 'on' : 'off'}>
          <View style={{position: 'relative'}}></View>
        </CameraView>
      )}

      {!!displayPhoto && (
        <View style={{flex: 1, backgroundColor: 'black', position: 'relative'}}>
          {!!tempPhoto && (
            <ExpoImage
              source={{uri: tempPhoto}}
              style={{flex: 1, paddingBottom: isVideo ? 120 : 0}}
              resizeMode={'contain'}
            />
          )}
          {!!photo && (
            <ExpoImage
              source={{uri: photo}}
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: isVideo ? 120 : 0,
                right: 0,
              }}
              resizeMode={'contain'}
            />
          )}
          {!!video && (
            <View
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                bottom: 120,
                right: 0,
                width,
              }}>
              <Video
                source={{uri: video}}
                style={{
                  flex: 1,
                  // backgroundColor: 'red',
                }}
                resizeMode={ResizeMode.COVER}
                isLooping
                shouldPlay
                useNativeControls
                // controls
              />
            </View>
          )}
        </View>
      )}
      <Saving />
      {!!uploading && <Uploading />}
      {!!permissionsDenied && (
        <PermissionsWarning requestPermissions={requestPermissions} />
      )}
      {!!text && (
        <View
          style={{
            alignItems: 'center',
            backgroundColor: constants?.color?.gray3_50,
            justifyContent: 'center',
            left: 0,
            minHeight: 60,
            maxHeight: 220,
            paddingHorizontal: 10,
            paddingVertical: 10,
            position: 'absolute',
            zIndex: 100,
            right: 0,
            top: Platform.OS == 'android' ? 50 : 30,
            width,
          }}>
          <BodyText
            text={text}
            textStyle={{
              color: constants?.color?.white,
              marginTop: 5,
            }}
          />
        </View>
      )}
      {!!isVideo && !isDone && <VideoTimeLeft isRecording={isRecording} />}
      <CameraControlRow
        handleCapture={handleCapture}
        showSave={isDone}
        handleStopRecording={() => cameraRef.current?.stopRecording()}
        isRecording={isRecording}
        handleSubmitChallenge={handleSubmitChallenge}
        toggleCamera={() => {
          setFacing(facing === 'back' ? 'back' : 'front');
        }}
        handleRetake={() => {
          resetCamera();
        }}
        handleCameraRoll={handleCameraRoll}
        facing={facing}
        initialPhotoUrl={initialPhotoUrl}
        flash={flash}
        handleShare={handleShare}
        toggleFlash={() => {
          setFlash(!flash);
        }}
      />
    </View>
  );
}

const Saving = () => {
  const photoTaken = useTypedSelector(
    (state) => state.current_screen?.photoTaken,
  );
  console.log_on_production({photoTaken});

  useEffect(() => {
    setTimeout(() => {
      if (photoTaken) {
        dispatchAction(setPhotoTaken(0));
      }
    }, 3500);
  }, []);

  if (!photoTaken) {
    return <></>;
  }
  return (
    <View
      style={{
        ...constants?.style.fillAbsolute,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#000000fd',
        zIndex: 100,
      }}>
      <View
        style={{
          backgroundColor: constants?.color?.orange,
          width: 140,
          height: 140,
          borderRadius: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CustomActivityIndicator
          size={'large'}
          color={constants?.color?.white}
        />
        <BodyText
          text={'Saving...'}
          textStyle={{color: constants?.color?.white}}
        />
      </View>
    </View>
  );
};

const Uploading = () => {
  const {onScore, hideScoring = false} = useParams<'PhotoVideoCamera'>();
  console.log_on_production({onScore, hideScoring});
  return (
    <View
      style={{
        ...constants?.style.fillAbsolute,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: constants?.color?.gray3_50,
      }}>
      <View
        style={{
          backgroundColor: constants?.color?.orange,
          width: 140,
          height: 140,
          borderRadius: 10,
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <CustomActivityIndicator
          size={'large'}
          color={constants?.color?.white}
        />
        <BodyText
          text={!!onScore && !hideScoring ? 'Scoring...' : 'Saving...'}
          textStyle={{color: constants?.color?.white}}
        />
      </View>
    </View>
  );
};
