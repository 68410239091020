import useTypedSelector from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState} from 'react';
import {Platform, TouchableOpacity, View} from 'react-native';
import {SafeAreaView} from 'react-native-safe-area-context';
import ExpoImage from 'src/Utils/ExpoImage';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';
import {calculateDistance} from 'src/Utils/distanceHelpers';
import {formatNumberDisplayAmount} from 'src/Utils/formatHelper';
import {setNavigationCompleteTime} from 'src/Utils/huntFunctions_v2';
const basePhotoUrl = 'photos.letsroam.com';

/////////////// components
import {navigationRef} from 'src/Nav/navigationHelpers';
import {
  BodyText,
  ClassicIconButton,
  CloseIconButton,
  ContinueButton,
  ExpandFullscreenButton,
} from 'src/Modules/CommonView';
import ChallengeMap from 'src/ScavengerHunt_v2/Challenges/ChallengeMap';
import {
  useLocation,
  useLocationId,
  usePercentageComplete,
} from 'src/Hooks/gameHooks';
// import {saveUserLocation} from 'src/Redux/reducers/user_location.reducer';
import {cloudFlareOptimizeImage} from 'src/Utils/helpers';
import {finishHunt, saveGroupPosition} from 'src/Utils/huntFunctions';

import CustomActivityIndicator from 'src/Modules/CommonView/CustomActivityIndicator';
import {DistanceAway} from 'src/ScavengerHunt_v2/Game/DistanceAway';

import {TLocation} from 'src/types/TLocation';
import {TRegion} from 'src/types/TRegion';

const ActiveHunt: React.FC<{
  huntLocation?: TLocation;
  handlePress: () => void;
  map?: boolean;
}> = ({huntLocation, handlePress, map}) => {
  const name = huntLocation?.name || '';
  const address = huntLocation?.address || '';
  const photoLarge = huntLocation?.photoLarge;

  // Alert.alert('hi');
  const photoLargeUrl = photoLarge
    ? photoLarge?.replace('./', 'https://photos.letsroam.com/')
    : '';
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={{
        position: 'absolute',
        bottom: 10,
        left: 10,
        backgroundColor: constants?.color?.white_90,
        padding: 10,
        borderRadius: 10,
        maxWidth: 250,
      }}
      disabled={!handlePress}
      onPress={handlePress}>
      <View style={{flexDirection: 'row', alignItems: 'center'}}>
        {!!huntLocation?.photoLarge && !!map && (
          <ChallengeMap
            huntLocation={huntLocation}
            style={{
              width: 60,
              height: 60,
              minWidth: 60,
              minHeight: 60,
              borderRadius: 8,
            }}
            mapStyle={{
              width: 60,
              height: 60,
              borderRadius: 8,
            }}
            classic
          />
        )}
        {!map && (
          <ExpoImage
            resizeMode={'cover'}
            source={
              map
                ? {
                    // mapImage generic google map
                    uri: cloudFlareOptimizeImage(
                      'https://photos.letsroam.com/photos_other/scavenger-hunt-location-1648579599_large.jpg',
                    ),
                  }
                : {uri: photoLargeUrl}
            }
            style={{
              width: 60,
              height: 60,
              borderRadius: 8,
            }}
          />
        )}
        <View>
          <BodyText
            text={name}
            textStyle={{
              fontSize: 14,
              fontWeight: '700',
              lineHeight: 14,
              marginTop: 0,
              marginBottom: 0,
              flexWrap: 'wrap',
              maxWidth: 160,
            }}
          />
          {!!address && (
            <BodyText
              text={address}
              textStyle={{
                marginTop: 5,
                color: constants?.color?.gray3,
                fontSize: 12,
                lineHeight: 14,
                maxWidth: 140,
                flexWrap: 'wrap',
              }}
            />
          )}
        </View>
      </View>
    </TouchableOpacity>
  );
};

const NavigationChallengeModal = () => {
  const {width} = useWindowDimensionsSafe();
  console.log('NavigationChallengeModal');

  const email = useTypedSelector((state) => state.user?.info?.email);
  const locationList = useTypedSelector((state) => state.game_v2?.locationList);
  const locations = useTypedSelector((state) => state.game_v2?.locations);

  const allChallenges = useTypedSelector(
    (state) => state.game_v2?.allChallenges || {},
  );
  const timerStart = useTypedSelector((state) => state.game_v2?.timerStart);
  const timerLimitMinutes = useTypedSelector(
    (state) => state.game_v2?.timerLimitMinutes || 0,
  );
  const locationId = useLocationId();
  const location = useLocation();

  const [fullscreenMap, setFullscreenMap] = useState<boolean>(false);
  const [view, setView] = useState<string>('map');

  const checkedInNavigate = () => {
    if (location && location.required_checkout_location) {
      return true;
    }
    navigationRef?.goBack(
      'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
    );
    return navigationRef.navigate(
      'LocationChallengeListModal',
      {
        locationId,
      },
      'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
    );
  };

  const userLat =
    useTypedSelector((state) => state.location?.region?.latitude) || 0;
  const userLong =
    useTypedSelector((state) => state.location?.region?.longitude) || 0;

  const getDistanceAway = () => {
    if (!location) return 0;

    const {lat = 0, long = 0} = location;

    const distance = calculateDistance(
      Number(lat), //location lat
      Number(long), // location long
      userLat,
      userLong,
      'FEET',
    );
    return typeof distance === 'string' ? parseInt(distance) : distance;
  };

  const checkNavigationCompletion = async () => {
    if (!location) return console.log('no location');
    const huntLocation = location;
    const distanceAway = getDistanceAway();
    const {
      region = {} as TRegion,
      locationAccuracy = 2600,
      required_checkout_location,
    } = location || {};
    const {latitude = 0, longitude = 0} = region || {};
    saveGroupPosition({latitude, longitude}, 'NavigationChallengeModal');

    console.log('checkNavigationCompletion', {
      required_checkout_location,
      huntLocation,
    });

    //const locationAccuracy = 400; // radius to be considered at location in ft

    const userAccuracy = location.accuracy;
    const locationOffset = (locationAccuracy || 0) + (userAccuracy || 0) * 3.28;
    //// need flag for overriding geo-fencing, currently bypassed for dev
    const devOverride =
      false && (__DEV__ || email?.includes?.('letsroam.com')) && true;
    if (huntLocation?.checkInOverride) {
      const isClose = distanceAway < locationOffset;
      return huntLocation?.checkInOverride(isClose, distanceAway);
    }

    const handleNavigation = async () => {
      if (!!locationId) {
        await setNavigationCompleteTime(locationId, location, distanceAway);
      }

      return navigationRef.navigate(
        'NavigationChallengeScoreModal',
        {
          onClose: checkedInNavigate,
          locationId,
        },
        'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
      );
    };

    const isClose =
      distanceAway < locationOffset || devOverride || Platform.OS == 'web';
    if (isClose) {
      return handleNavigation();
    }

    const items = [
      {
        heading: '🗺️ Maximize Your Points!',
        description: "Are you at the location? Here's how you can score big:\n",
      },
      {
        heading: '📍 Get Close',
        description:
          'Earn the most points by getting as close as possible to the check-in point',
      },
      {
        heading: '⏳ Speedy Answers Win',
        description:
          'Earn extra points by answering all the questions at a location faster.',
      },
    ];

    navigationRef.navigate(
      'ConfirmModal',
      {
        handleConfirm: async () => {
          navigationRef?.goBack(
            'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
          );
          handleNavigation();
        },
        confirmText: `Check In Now`,
        cancelText: 'Keep Hunting',
        confirmColor: constants?.color?.blue,
        items,
      },
      'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
    );
  };
  const huntLocation = location;

  const teamName = useTypedSelector((state) => state?.group?.info?.teamName);

  const completionPercent = usePercentageComplete() || 0;
  if (!locations) {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: constants?.color?.white,
        }}>
        <CustomActivityIndicator />
      </View>
    );
  }

  const photoLarge = huntLocation?.photoLarge;
  const completeTime = huntLocation?.completeTime;
  const challengeList = huntLocation?.challengeList;
  let description = huntLocation?.description;

  description = description?.replace(/\[TEAM_NAME\]/gi, teamName || '') || '';

  const almostEndOfHunt =
    completionPercent > 75 ||
    (timerStart &&
      timerLimitMinutes * 60 * 1000 - (Date.now() - timerStart) <
        constants?.checkoutTimeLimit * 60 * 1000);
  const formattedMapLocations =
    locationList
      ?.filter?.((key) => key !== locationId)
      ?.map?.((key) => {
        const location = {...locations[key]};
        location.totalLocationPoints =
          (location.challengeList &&
            (location.challengeList || []).reduce((sum, key) => {
              const challenge = !!allChallenges && allChallenges[key];
              return (challenge?.points || 0) + sum;
            }, location.points || 0)) ||
          0;

        location.isTotalComplete =
          !!allChallenges && !!allChallenges[key]?.completeTime;
        return location;
      }) || [];
  if (fullscreenMap) {
    return (
      <SafeAreaView style={{flex: 1, position: 'relative'}}>
        <View style={{flex: 1, position: 'relative'}}>
          <ChallengeMap
            huntLocation={huntLocation || undefined}
            style={{
              flex: 1,
              minHeight: 50,
              minWidth: 50,
              borderRadius: 10,
            }}
            mapStyle={{borderRadius: 10}}
            classic={true}
            locations={formattedMapLocations}
          />
          <CloseIconButton
            testID={'mapCloseButton'}
            handlePress={() =>
              navigationRef?.goBack(
                'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
              )
            }
            side={Platform.OS === 'android' ? 'left' : 'right'}
          />
          <ActiveHunt
            handlePress={() => {}}
            huntLocation={huntLocation || undefined}
          />
          <ExpandFullscreenButton
            isFullscreen={fullscreenMap}
            handlePress={() => setFullscreenMap(false)}
          />
        </View>
      </SafeAreaView>
    );
  }
  const totalLocationPoints =
    (challengeList &&
      challengeList.reduce((sum, key) => {
        const challenge = allChallenges[key];
        return (challenge?.points || 0) + sum;
      }, huntLocation?.points || 0)) ||
    huntLocation?.totalLocationPoints;
  0;
  const earnedLocationPoints =
    (challengeList &&
      (challengeList || []).reduce((sum, key) => {
        const challenge = allChallenges[key];
        const parsedSum = typeof sum === 'string' ? parseInt(sum) : sum;
        return (challenge.groupPointsEarned || 0) + parsedSum;
      }, huntLocation?.groupPointsEarned || 0)) ||
    0;
  const photoLargeUrl = photoLarge
    ? `https://${basePhotoUrl}${photoLarge && photoLarge?.substring?.(1)}`
    : '';

  return (
    <SafeAreaView style={{flex: 1, justifyContent: 'flex-end'}}>
      <TouchableOpacity
        style={{flex: 1}}
        onPress={() =>
          navigationRef?.goBack(
            'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
          )
        }
      />
      <View
        style={{
          backgroundColor: constants?.color?.white,
          width,
          marginTop: 5,
          borderRadius: 10,
        }}>
        {view === 'map' && (
          <View style={{position: 'relative'}}>
            <ChallengeMap
              huntLocation={huntLocation || undefined}
              style={{
                minHeight: 300,
                height: !huntLocation?.name ? 500 : 300,
                minWidth: width,
                width,
                borderRadius: 10,
              }}
              mapStyle={{borderRadius: 10}}
              classic={true}
              locations={formattedMapLocations}
            />
            <DistanceAway />
            <ExpandFullscreenButton
              isFullscreen={fullscreenMap}
              handlePress={() => {
                setFullscreenMap(true);
              }}
            />
            <CloseIconButton
              testID={'mapCloseButton'}
              handlePress={() =>
                navigationRef?.goBack(
                  'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
                )
              }
              side={Platform.OS === 'android' ? 'left' : 'right'}
            />
            {!!huntLocation?.name && (
              <ActiveHunt
                huntLocation={huntLocation}
                handlePress={() => {
                  !!photoLargeUrl && setView('photo');
                }}
              />
            )}
          </View>
        )}
        {view === 'photo' && (
          <View style={{position: 'relative'}}>
            <ExpoImage
              resizeMode={'cover'}
              source={{uri: photoLargeUrl}}
              style={{
                minHeight: 300,
                height: !huntLocation?.name ? 500 : 300,
                width,
                borderRadius: 10,
              }}
            />
            <DistanceAway />
            <CloseIconButton
              testID={'mapCloseButton'}
              handlePress={() =>
                navigationRef?.goBack(
                  'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
                )
              }
              side={Platform.OS === 'android' ? 'left' : 'right'}
            />
            {!!huntLocation?.name && (
              <ActiveHunt
                huntLocation={huntLocation}
                handlePress={() => {
                  setView('map');
                }}
                map
              />
            )}
          </View>
        )}
        {!!huntLocation?.name && (
          <View>
            <View
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                borderBottomWidth: 1,
                borderBottomColor: constants?.color?.white1,
                padding: 10,
              }}>
              <View style={{marginHorizontal: 5}}>
                <ClassicIconButton
                  handlePress={() => {}}
                  text={`${
                    earnedLocationPoints
                      ? `${formatNumberDisplayAmount(
                          earnedLocationPoints as number,
                        )}/${formatNumberDisplayAmount(totalLocationPoints)}`
                      : formatNumberDisplayAmount(totalLocationPoints)
                  } pts`}
                  size={24}
                  icon="flash"
                  color="teal"
                  iconColor="white"
                  inline
                />
              </View>
              {!huntLocation?.required_checkout_location && (
                <View style={{marginHorizontal: 5}}>
                  <ClassicIconButton
                    handlePress={() => null}
                    text={`${
                      (huntLocation?.challengeList || []).length ||
                      huntLocation?.challengeCount ||
                      0
                    } Challenges`}
                    size={24}
                    icon="compass"
                    color="orange"
                    iconColor="white"
                    inline
                  />
                </View>
              )}
            </View>
            <BodyText
              textStyle={{marginBottom: 10, marginTop: 10}}
              text={description}
            />
            {!completeTime && !!huntLocation?.required_checkout_location && (
              <BodyText
                textStyle={{
                  marginBottom: 10,
                  marginTop: 10,
                  color: constants?.color?.red,
                }}
                text={` This is the ending location! Check in here during the final ${constants?.checkoutTimeLimit} minutes of the hunt, or when you finish all other challenges.`}
              />
            )}
            {!!completeTime && !!huntLocation?.required_checkout_location && (
              <View style={{paddingBottom: 50}}>
                <BodyText
                  textStyle={{
                    marginBottom: 10,
                    marginTop: 10,
                    color: 'green',
                  }}
                  text={`You have checked into the final location. It is time to finish your hunt to get all your bonus points.`}
                />
                <ContinueButton
                  text={'Finish Hunt!'}
                  handlePress={() => {
                    console.log('done???');
                    console.log('finish hunt');

                    console.log('finishHunt');
                    finishHunt();
                    navigationRef?.goBack(
                      'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
                    );
                    navigationRef?.goBack(
                      'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
                    );
                  }}
                  containerStyle={{
                    marginTop: 30,
                    marginBottom: 50,
                  }}
                />
              </View>
            )}
            <View style={{height: 55, paddingBottom: 10, paddingHorizontal: 5}}>
              {!completeTime &&
                (!huntLocation?.required_checkout_location ||
                  almostEndOfHunt) && (
                  <ContinueButton
                    text={'Check In'}
                    handlePress={checkNavigationCompletion}
                  />
                )}

              {!completeTime &&
                !!huntLocation?.required_checkout_location &&
                !almostEndOfHunt && (
                  <ContinueButton
                    text={'Finish Hunt Now Anyways'}
                    handlePress={() => {
                      navigationRef.navigate(
                        'ConfirmModal',
                        {
                          titleText: `Are You Sure?`,

                          bodyText:
                            'Make sure you have finished all your challenges. This can not be un-done.',
                          confirmText: 'Finish Hunt Right Now',
                          cancelText: 'Continue Playing',
                          handleConfirm: async () => {
                            navigationRef?.goBack(
                              'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
                            );
                            navigationRef?.goBack(
                              'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
                            );
                            console.log('finishHunt');
                            return await finishHunt();
                          },
                        },
                        'ScavengerHunt_v2/Classic/NavigationChallengeModal.tsx',
                      );
                    }}
                    background={constants?.color?.gray3}
                  />
                )}
              {!!completeTime && !huntLocation?.required_checkout_location && (
                <ContinueButton
                  testID={'viewChallenges'}
                  text={'View Challenges'}
                  handlePress={checkedInNavigate}
                  background={constants?.color?.blue}
                />
              )}
            </View>
          </View>
        )}
      </View>
    </SafeAreaView>
  );
};

export default NavigationChallengeModal;
