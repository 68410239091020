import useTypedSelector, {deepEqualityCheck} from 'src/Hooks/useTypedSelector';
import constants from 'src/constants';
import React, {useState, useEffect} from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {
  ScrollView,
  Linking,
  TouchableOpacity,
  View,
  Alert,
  Platform,
} from 'react-native';
import useWindowDimensionsSafe from 'src/Modules/Native/useWindowDimensionsSafe';

import ModalOmniBar from 'src/Modules/OmniBars/ModalOmniBar';
import ExpoImage from 'src/Utils/ExpoImage';

import SocialReviewItem from 'src/ScavengerHunt_v2/Challenges/SocialReviewItem';
import {useUserId} from 'src/Hooks/reduxHooks';
/////////////////////////////////////////////
/////////////// components
import {BodyText} from 'src/Modules/CommonView';

/////////////////////////////////////////////
/////////////// utils
import {applyReviewBonusPoints} from 'src/Utils/huntFunctions';
import 'react-native-get-random-values';
import {v4 as uuidv4} from 'uuid';

import {updateDataAtPath} from 'src/Utils/fireUtils';

const googleIcon = require('src/Images/googleIcon.png');
const facebookIcon = require('src/Images/facebookIcon.png');
const specialPin = require('src/Images/Icons/activePin.png');

const facebookFollow = require('src/Images/Icons/facebook.png');
const instagramFollow = require('src/Images/Icons/instagram.png');
const twitterFollow = require('src/Images/Icons/twitter.png');

const HuntSocialBonuses: React.FC = () => {
  const {width} = useWindowDimensionsSafe();

  const groupId = useTypedSelector((state) => state.group?.info?.groupId);
  const players =
    useTypedSelector(
      (state) => state.group?.info?.players,
      deepEqualityCheck,
    ) || {};

  const huntId = useTypedSelector((state) => state.group?.info?.huntId);
  const eventId =
    useTypedSelector((state) => state.event?.info?.eventId || '') || '';

  const voucher1 = useTypedSelector(
    (state) => state.user?.info?.voucher1,
    deepEqualityCheck,
  );
  const userId = useUserId();

  const playerBonuses:
    | {
        [x: string]: {
          bonusId?: string;
          bonusType?: string;
          points?: number;
          pointsEarned?: number;
        };
      }
    | undefined =
    players && userId && players[userId]?.bonuses
      ? players[userId]?.bonuses
      : {};

  const [canShowInstagram, setCanShowInstagram] = useState<boolean>(true);
  const [showGrouponReview, setShowGrouponReview] = useState<boolean>(false);

  const checkSocialChallenges = () => {
    Linking?.canOpenURL('instagram://').then((supported) => {
      if (!supported) {
        setCanShowInstagram(false); //can't open instagram
      } else {
        return console.log('instagram urls can be opened');
      }
    });
  };

  useEffect(() => {
    // has a groupon voucher
    if (!!voucher1) {
      // checking if has the groupon app
      Linking?.canOpenURL('groupon://').then((supported) => {
        if (!supported) {
          setShowGrouponReview(false); //can't open groupon
        } else {
          // if both true then showing groupon reviews as an option
          Alert.alert('hi');

          return setShowGrouponReview(false);
        }
      });
    }
    checkSocialChallenges();
  }, []);

  const handleInstagramFollowPress = () => {
    Linking?.openURL('instagram://user?username=letsroam');

    setTimeout(() => {
      !playerBonuses?.['instagramFollow'] &&
        userId &&
        !eventId &&
        applyReviewBonusPoints('instagramFollow', 400, userId);
    }, 300);
  };

  const handleFacebookFollowPress = () => {
    Linking?.openURL('https://www.facebook.com/LetsRoam/');

    setTimeout(() => {
      !playerBonuses?.['facebookFollow'] &&
        userId &&
        !eventId &&
        applyReviewBonusPoints('facebookFollow', 200, userId);
    }, 300);
  };

  const handleTwitterFollowPress = () => {
    Linking?.openURL('https://twitter.com/letsroam_real');

    setTimeout(() => {
      !playerBonuses?.['twitterFollow'] &&
        userId &&
        !eventId &&
        applyReviewBonusPoints('twitterFollow', 200, userId);
    }, 300);
  };

  const tripAdvisorReviewLink =
    ('https://www.letsroam.com/leave_a_hunt_review/' + huntId || 1) +
    '?utm_source=post_hunt_email&' +
    uuidv4();

  const reviewText = `Leave A Review:`;

  ('https://www.yelp.com/writeareview/biz/t5r9xSv3c8xAMyhRQS1IPg');

  // const isTeamBuilding = useTypedSelector(
  //   (state) => state.group?.info?.theme == 'Team Building',
  // );

  const handleDone = () => {
    updateDataAtPath(
      `scavengerhunt/groups/${groupId}/players/${userId}/reviews/huntSocialBonusDone`,
      true,
    );
  };

  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: constants?.color?.white,
      }}>
      <ScrollView style={{flex: 1}} showsVerticalScrollIndicator={true}>
        {!eventId && (
          <BodyText
            text={`Bonus Points`}
            textStyle={{
              textAlign: 'center',
              fontSize: 20,
              lineHeight: 22,
              fontWeight: '700',
              marginVertical: 10,
              marginTop: 10,
            }}
          />
        )}
        <View
          style={{
            alignItems: 'flex-start',
            paddingTop: 0,
            paddingBottom: 140,
            position: 'relative',
          }}>
          <BodyText
            text={`Follow Us:`}
            textStyle={{
              textAlign: 'left',
              paddingLeft: 5,
              fontSize: 18,
              lineHeight: 20,
              marginLeft: 10,
              marginTop: 20,
              marginBottom: 10,
              fontWeight: '700',
            }}
          />

          <View
            style={{
              marginHorizontal: 10,
              width: width - 20,
              flexDirection: 'row',
              justifyContent: 'space-around',
              alignItems: 'center',
            }}>
            <TouchableOpacity
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 5,
              }}
              activeOpacity={0.8}
              onPress={handleFacebookFollowPress}>
              <ExpoImage
                style={{width: 50, height: 50}}
                source={facebookFollow}
                resizeMode={'contain'}
              />
              <BodyText
                text={
                  playerBonuses?.['facebookFollow']
                    ? 'Thanks!'
                    : eventId
                    ? ''
                    : '+200 pts'
                }
                textStyle={{
                  textAlign: 'center',
                  marginTop: 5,
                  fontSize: 14,
                }}
              />
            </TouchableOpacity>
            {!!canShowInstagram && (
              <TouchableOpacity
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: 5,
                }}
                activeOpacity={0.8}
                onPress={handleInstagramFollowPress}>
                <ExpoImage
                  style={{width: 75, height: 75}}
                  source={instagramFollow}
                  resizeMode={'contain'}
                />
                <BodyText
                  text={
                    playerBonuses?.['instagramFollow']
                      ? 'Thanks!'
                      : eventId
                      ? ''
                      : '+400 pts'
                  }
                  textStyle={{
                    textAlign: 'center',
                    marginTop: 5,
                    fontSize: 14,
                  }}
                />
              </TouchableOpacity>
            )}
            <TouchableOpacity
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 5,
              }}
              activeOpacity={0.8}
              onPress={handleTwitterFollowPress}>
              <ExpoImage
                style={{width: 50, height: 50}}
                source={twitterFollow}
                resizeMode={'contain'}
              />
              <BodyText
                text={
                  playerBonuses?.['twitterFollow']
                    ? 'Thanks!'
                    : eventId
                    ? ''
                    : '+200 pts'
                }
                textStyle={{
                  textAlign: 'center',
                  marginTop: 5,
                  fontSize: 14,
                }}
              />
            </TouchableOpacity>
          </View>
          {!!showGrouponReview && (
            <BodyText
              text={reviewText}
              textStyle={{
                textAlign: 'left',
                paddingLeft: 5,
                fontSize: 18,
                lineHeight: 20,
                marginLeft: 10,
                marginTop: 20,
                marginBottom: 10,
                fontWeight: '700',
              }}
            />
          )}

          <BodyText
            text={!!eventId ? 'Leave A Review' : 'Earn Bonus Points or Rewards'}
            textStyle={{
              textAlign: 'left',
              paddingLeft: 5,
              fontSize: 18,
              lineHeight: 20,
              marginLeft: 10,
              marginTop: 20,
              marginBottom: 10,
              fontWeight: '700',
            }}
          />
          <SocialReviewItem
            showReviewItem={true}
            reviewLink={`https://www.letsroam.com//api/trip_advisor_review/hunt/${huntId}`}
            reviewLetter={'T'}
            reviewColor={constants?.color.blue}
            reviewText={'Leave a Tripadvisor Review'}
            points={!!eventId ? 0 : 300}
            reviewType={'googleReview'}
            groupId={groupId}
          />

          <SocialReviewItem
            showReviewItem={true}
            reviewLink={
              'https://search.google.com/local/writereview?placeid=ChIJ4dKpXeZ5bIcRhTtG7eztzw0'
            }
            reviewIcon={googleIcon}
            reviewColor={'#dd4b39'}
            reviewText={'Leave a Google Review'}
            points={!!eventId ? 0 : 150}
            reviewType={'googleReview'}
            groupId={groupId}
          />
          <SocialReviewItem
            showReviewItem={true}
            reviewLink={
              'https://www.facebook.com/pg/LetsRoam/reviews/?ref=page_internal&mt_nav=0&soft=composer'
            }
            reviewIcon={facebookIcon}
            reviewColor={'#3B5998'}
            reviewText={'Leave a Facebook Review'}
            points={!!eventId ? 0 : 150}
            reviewType={'facebookReview'}
            groupId={groupId}
          />

          <SocialReviewItem
            showReviewItem={showGrouponReview}
            reviewLink={'groupon://stuff'}
            reviewMessage={
              'To leave a review tap the leave review button here. Then tap the my stuff tab in the Groupon app then my Groupons. Then tap on your groupon and tap: write a review.'
            }
            reviewLetter={googleIcon}
            reviewColor={'#82b548'}
            reviewText={'Leave a Groupon Review'}
            points={!!eventId ? 0 : 500}
            reviewType={'grouponReview' + huntId}
            groupId={groupId}
          />
          <SocialReviewItem
            showReviewItem={true}
            reviewLink={'https://www.trustpilot.com/evaluate/letsroam.com'}
            reviewTitle={'How To Leave A Review'}
            reviewLetter={'T'}
            reviewColor={'#00b67a'}
            reviewText={'Leave a TrustPilot Review'}
            points={!!eventId ? 0 : 150}
            reviewType={'grouponReview' + huntId}
            groupId={groupId}
          />
        </View>
      </ScrollView>
      <ModalOmniBar
        right={{
          handlePress: () => handleDone(),
          icon: 'arrow-right-circle',
          text: 'Skip',
        }}
        omniButton={{
          icon: 'check',
          iconLibrary: 'FontAwesome',
          text: 'See Final Score',
          color: constants?.color?.orange,
          handlePress: () => handleDone(),
          loading: false,
        }}
      />
    </SafeAreaView>
  );
};

export default HuntSocialBonuses;
